import { init } from '@sentry/nextjs'
import { Router, useRouter } from 'next/router'
import { stringify } from 'querystring'
import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import Button from '~/components/ui/button/button'
import { Filter } from '~/components/ui/icons/filter'
import { ReviewService } from '~/services/review.service'
import { UserService } from '~/services/user.service'
// import { AuthState } from '~/stores/auth'
import { UseNotificationState } from '~/stores/notify'
import { Logger } from '~/util'
import { AuthState } from './auth'
import { DetailStore } from './detail'
import { Config } from '~/config/config'

const ReviewStateState = () => {
  const router = useRouter()
  const authState = AuthState.useContainer()
  const [limit, setLimit] = useState(12)
  const [page, _setPage] = useState(1)
  const [sort, _setSort] = useState('DESC')
  const [filter, setFilter] = useState('')
  const [notifyError, setNotifyError] = useState(false)

  const notify = UseNotificationState.useContainer()
  const [reviewItem, setReviewItem] = useState([])

  const [newReview, setNewReview] = useState({
    title: '',
    text: '',
    rating: 0,
  })
  const [newRatings, setNewRatings] = useState({
    title: '',
    text: '',
    rating: 0,
    ratings: [],
    images: [],
    // recommended: '',
  })
  const [editRatings, setEditRatings] = useState({
    title: 'title',
    text: 'text',
    rating: 'rating',
    ratings: [],
    images: [],
    // recommended: '',
  })
  const [formType, setFormType] = useState('NULL' as 'NEW' | 'EDIT' | 'NULL')

  const updateNewReview = (key: string, value: any) => {
    setNewReview({ ...newReview, [key]: value })
  }
  const updateNewRatings = (key: string, value: any) => {
    setNewRatings({ ...newRatings, [key]: value })
  }
  // const authState = AuthState.useContainer()
  useEffect(() => {
    if (authState.state.id) {
      // initialData()
    }
  }, [authState.state.id])

  const postNewReview = async (data) => {
    const url = `${Config.SITE_URL}`
    if (newRatings.title != '' && newRatings.text != '') {
      const { id } = router.query
      const body: any = {
        ...newRatings,
        itemCode: router.query.itemCode,
        // productId: Number(id),
        slug: router.query.slug,
        productCode: router.query.code,
      }
      await ReviewService.addNewReview(body)
        .then((_res) => {
          notify.appNotification('Review Added Successfully', 'SUCCESS')
          if (!!data == false) {
            router.push(`${Config.SITE_URL}`)
          } else {
            router.back()
          }
        })
        .catch(Logger.error)
    } else {
      setNotifyError(true)
      setTimeout(() => {
        setNotifyError(null)
      }, 3000)
      // notify.appNotification('Fill the Mandatory Fields', 'ERROR')
    }
  }

  const updateReview = async () => {
    const url = `${Config.SITE_URL}`
    if (newRatings.title != '' && newRatings.text != '') {
      const { id } = router.query
      // const { code }: any = router.query.itemCode
      const body: any = {
        ...newRatings,
        reviewId: router.query.reviewId,
        itemCode: router.query.code,
        productId: Number(id),
      }
      const reviewId = router.query.reviewId
      await ReviewService.updateReview(reviewId, body)
        .then((_res) => {
          notify.appNotification('Review Updated Successfully', 'SUCCESS')
          router.push(`${Config.SITE_URL}`)
        })
        .catch(Logger.error)
    } else {
      notify.appNotification('Fill the Mandatory Fields', 'ERROR')

      authState.setShowLoginForm(true)
    }
  }

  const getCurrentReview = (id: any) => {
    ReviewService.getOneReview(id)
      .then((res) => {
        setNewRatings(res.data.data)
        Logger.log('___________________-', res.data.data)
      })
      .catch(Logger.error)
  }

  const initialData = async () => {
    try {
      const { data } = await ReviewService.getMyReview(limit, page, sort)
      if (data.data) {
        setReviewItem(data.data.data)
      }
      return data.data
    } catch (err) {
      Logger.error(err)
    }
  }

  const delReview = (reviewId: any) => {
    return ReviewService.deleteReview(reviewId)
      .then(async (_res) => {
        window.location.reload()
      })
      .catch(Logger.error)
  }

  return {
    newReview,
    updateNewReview,
    newRatings,
    reviewItem,
    updateNewRatings,
    postNewReview,
    formType,
    setFormType,
    updateReview,
    delReview,
    initialData,
    getCurrentReview,
    setNotifyError,
    notifyError,
  }
}

export const ReviewState: any = createContainer(ReviewStateState)
