import React, { useLayoutEffect, useState } from 'react'
import style from './Image-card.module.scss'
import Link from 'next/dist/client/link'
import { InnerHtml } from '../../inner-html/inner-html'

interface ImageCardProps {
  data: string
  title: string
  bgcolor: string
  path: string
  rel?: string
  mobilepath?: string
}

export const ImageCard = (props: ImageCardProps) => {
  const [isMobilePath, setIsMobilePath] = useState('')
  useLayoutEffect(() => {
    if (window.innerWidth < 500) {
      setIsMobilePath(props.mobilepath)
    } else {
      setIsMobilePath(props.path)
    }
  }, [props])
  return (
    <div className={` ${props.bgcolor}`}>
      <div className={` w-full rounded-md `}>
        <Link
          href={isMobilePath}
          className={`flex items-center justify-center p-3`}
          target="_blank"
          rel="noreferrer"
        >
          <InnerHtml data={props.data} />
          <span
            className={`capitalize text-sm font-medium p-1 ml-2 text-gray-800 ${style.social_titledata}`}
          >
            {props.title}
          </span>
        </Link>
      </div>
    </div>
  )
}
