import { FC } from 'react'

export type StoreIconProps = {
  iconColor?: string
  className?: string
  width?: string
}
export const StoreIcon: FC<StoreIconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={props.className ? props.className : 'h-5 w-5'}
    fill="none"
    viewBox="0 0 24 24"
    stroke={props.iconColor ? props.iconColor : 'currentColor'}
    strokeWidth={props.width ? props.width : '2'}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
    />
  </svg>
)
