import { FC } from 'react'

type HeartProp = {
  type: 'FILLED' | 'STROKE'
  pimStyle?: any
}
export const Heart: FC<HeartProp> = (props) => {
  const fillColor = () => {
    if (props.pimStyle == 'style12') {
      if (props.type == 'STROKE') {
        return '#A8A8A8'
      } else {
        return '#fff'
      }
    } else if (props.pimStyle == 'style18') {
      if (props.type == 'STROKE') {
        return '#ff7631'
      } else {
        return '#f00'
      }
    } else if (props.pimStyle == 'style11') {
      if (props.type == 'STROKE') {
        return '#fff'
      } else {
        return '#fff'
      }
    } else if (props.pimStyle == 'style2') {
      if (props.type == 'STROKE') {
        return '#ff7631'
      } else {
        return '#fff'
      }
    } else {
      if (props.type == 'STROKE') {
        return '#fff'
      } else {
        return '#f00'
      }
    }
  }
  const strokeColor = () => {
    if (props.pimStyle == 'style12') {
      if (props.type == 'STROKE') {
        return '#fff'
      } else {
        return '#fff'
      }
    } else if (props.pimStyle == 'style11') {
      if (props.type == 'STROKE') {
        return '#BEBEBE'
      } else {
        return '#f00'
      }
    } else if (props.pimStyle == 'style18') {
      if (props.type == 'STROKE') {
        return '#fff'
      } else {
        return '#fff'
      }
    } else if (props.pimStyle == 'style2') {
      if (props.type == 'STROKE') {
        return '#fff'
      } else {
        return '#fff'
      }
    } else {
      if (props.type == 'STROKE') {
        return '#424242'
      } else {
        return '#f00'
      }
    }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={'h-5 ' + (props.pimStyle == 'style12' ? 'w-3' : 'w-5')}
      fill={fillColor()}
      viewBox="0 0 24 24"
      stroke={strokeColor()}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.pimStyle == 'style11' ? 0.5 : 1}
        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
      />
    </svg>
  )
}
