export const sellingPrice = (arg) => {
  var sortedProducts1 = []

  let arr = []
  const currentdate = new Date()
  arg?.length >= 1 &&
    arg?.forEach((element: any) => {
      const startDate = new Date(element.startDate)
      let endDate: any =
        element.endDate == null ? element.endDate : new Date(element.endDate)
      if (currentdate.valueOf() >= startDate.valueOf()) {
        if (currentdate.valueOf() <= endDate?.valueOf() || endDate == null) {
          arr.push(element)
        }
      }
    })
  sortedProducts1 = arr.sort(
    (objA, objB) => Number(objB.startDate) - Number(objA.startDate)
  )

  return sortedProducts1
}

export const mrpPrice = (arg) => {
  var sortedProducts2 = []
  let t = 0
  let arr = []
  const currentdate = new Date()
  arg?.length >= 1 &&
    arg?.forEach((element: any) => {
      const startDate = new Date(element.startDate)
      // let endDate: any = element.endDate == null
      //   ? element.endDate
      //   : new Date(element.endDate)
      if (currentdate.valueOf() >= startDate.valueOf()) {
        // if (currentdate.valueOf() <= endDate?.valueOf() || endDate == null) {
        arr.push(element)
        // }
      }
    })
  sortedProducts2 = arr.sort(
    (objA, objB) => Number(objB.startDate) - Number(objA.startDate)
  )
  return sortedProducts2
}
