import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { MenuService } from '~/services/menu.service'
import { PimHomeService } from '~/services/pim.service'
import { Logger } from '~/util'
import Cookies from 'js-cookie'
import { CHANNEl } from '~/services/product.service'
export type MenuDataType = {
  category_id: number
  category_name: string
  children: MenuDataType[]
  content: {
    item_code: string
    pro_cat_id: string
    slug: string
    url: string
    url_type: string
  }[]
  icon: string
  id?: number
  parent_id?: number
  linkParams: any
  name?: any
}

const Menu = () => {
  const [menuData, setMenuData] = useState([] as MenuDataType[])

  useEffect(() => {
    init(1)
  }, [])
  const [fullyData, setFullyData] = useState(false)
  const getFullData = () => {
    if (!fullyData) {
      setFullyData(true)
      return init(1)
    }
  }

  const init = (partial) => {
    let channel = !!Cookies.get('store') ? CHANNEl.SHOP : CHANNEl.ONLINE
    return PimHomeService.PimMenuNavigation({ channel })
      .then((res) => {
        // const convert = (arr: any, _i: any) => {
        //   arr = arr.map((e: { name: any; children: any }) => {
        //     e.slug = `categories.lvl${_i}:=[\`${e.name}\`]`
        //     if (!!e.children) {
        //       if (!!e.children.length) {
        //         convert(e.children, _i + 1)
        //       }
        //     }
        //     return e
        //   })
        //   return arr
        // }
        let data = res.data.data.navigationdetails[0].navigation
        setMenuData(data)
        return
      })
      .catch(Logger.error)
  }

  return {
    init,
    menuData,
    setMenuData,
    getFullData,
  }
}

export const MenuState: any = createContainer(Menu)
