import Link from 'next/link'
import { useLayoutEffect, useState } from 'react'
import { useRouter } from 'next/router'
import style from '../app-bar.module.scss'
import { LanguageState } from '~/translations/computation'
import { AppBarText } from '../app-bar.translations'
import { SearchIcon } from '../../icons/search'
import { Close } from '../../icons/close'
import { QuickSearchStore } from '~/stores/quick-search'
import dynamic from 'next/dynamic'
import { Logger } from '~/util'
import { SearchService } from '~/services/search.service'
const Img: any = dynamic(() => import('../../img/img'))

/**
 * app bar search contains basic search bar
 * @returns {JSX.Element} returns app bar contains basic search bar
 */
export const AppBarSearch = (props: any) => {
  const lang = LanguageState.useContainer()
  // const searchState = SearchState.useContainer()
  const searchState = QuickSearchStore.useContainer()
  const router = useRouter()
  const [showList, setShowList] = useState(false)
  const [isSmall, setIsSmall] = useState(false)
  const [hover, setHover] = useState(-1)

  const submitHandle = () => {
    if (hover != -1) {
      let sel = searchState.searchList[hover]
      Logger.log(sel)
      if (sel.type == 1) {
        router.push({
          pathname: '/[slug]/p',
          query: {
            slug: sel.document.code,
          },
        })
      } else if (sel.type == 5) {
        router.push({
          pathname: `/${sel.category_code}`,
        })
      } else if (sel.type == 4) {
        router.push({
          pathname: `/${sel.document.model_code}`,
        })
      } else if (sel.type == 2) {
        searchState.setSearch(sel.document.keyword)
        const q = sel.document.keyword
        router.replace({
          pathname: `/s`,
          query: {
            q,
          },
        })
      }
      searchState.setSearchList([])
      return
    }
    const q = searchState.search
    if (q.length == 0) {
      return 0
    }
    searchState.setSearchList([])
    router.replace({
      pathname: `/s`,
      query: {
        q,
      },
    })
  }
  const handleOnClose = (ele: any) => {
    if (ele.type == 1) {
      searchState.setSearch(ele.document.name)
    }
    if (ele.type == 2) {
      searchState.setSearch(ele.document.keyword)
    }
    if (ele.type == 4) {
      searchState.setSearch(ele.document.model_code)
    }
    if (ele.type == 5) {
      searchState.setSearch(ele.document.category_code)
    }
    setShowList(false)
  }
  const gotoSearchCategories = (name: any) => {
    router
      .push({
        pathname: `/s`,
        query: {
          categories: `categories:=[${name}]`,
        },
      })
      .finally(() => {
        setShowList(false)
      })
  }
  useLayoutEffect(() => {
    setIsSmall(window.innerWidth <= 640)
    window.onresize = (_e) => {
      setIsSmall(window.innerWidth <= 640)
    }
  }, [])
  const keypressMonitor = (e: any) => {
    const exec = (i: any) => {
      let sel = searchState.searchList[i]
      if (!!sel) {
        if (sel.type == 1) {
          searchState.setSearch(sel.document.name)
        } else if (sel.type == 2) {
          searchState.setSearch(sel.document.keyword)
        } else if (sel.type == 4) {
          searchState.setSearch(sel.document.model_code)
        }
      }
    }
    if (e.keyCode == 38) {
      let calc = Number(hover) - 1
      if (calc < 0) {
        setHover(searchState.searchList.length - 1)
        exec(searchState.searchList.length - 1)
      } else {
        setHover(calc)
        exec(calc)
      }
    } else if (e.keyCode == 40) {
      let calc = Number(hover) + 1
      if (calc > searchState.searchList.length - 1) {
        setHover(0)
        exec(0)
      } else {
        setHover(calc)
        exec(calc)
      }
    }
  }
  return (
    <div>
      <form
        className={style.app_bar__search}
        onSubmit={(e) => {
          e.preventDefault()
          submitHandle()
          setShowList(false)
        }}
      >
        {isSmall && (
          <button type="button" aria-label="Left Align">
            <SearchIcon iconColor="#000" />
          </button>
        )}
        <input
          className={`text-black`}
          value={searchState.search}
          onChange={({ target }: any) => {
            setHover(-1)
            searchState.setSearch(target.value)
            setTimeout(() => searchState.searchSuggestion(target.value), 10)
            Logger.log('++', searchState.searchList)
            setShowList(true)
          }}
          onKeyDown={(e) => keypressMonitor(e)}
          type="text"
          placeholder={AppBarText.SearchPlaceHolder[lang.lang]}
        />
        {!isSmall && (
          <button
            className={`${style.search_desktop}`}
            type="submit"
            aria-label="Left Align"
          >
            <SearchIcon iconColor="#000" />
          </button>
        )}
      </form>
      {isSmall && (
        <button
          aria-label="Right Align"
          onClick={() => {
            props.setShowSearchForm(false)
          }}
          className={`${style.searchclose}`}
        >
          <Close />
        </button>
      )}

      {searchState.search && showList && (
        <div>
          <div
            className={`w-1/12 ${
              searchState.searchList.length > 6
                ? style.app_bar__search__suggestions
                : style.app_bar__search__suggestionss
            }`}
          >
            {searchState.searchList.map((ele: any, i: number) => (
              <Link
                className={`px-4 py-2 ${style.li} ${
                  i == hover && 'bg-gray-100'
                }`}
                key={i}
                onClick={() => handleOnClose(ele)}
                href={ele.href}
                target="_blank"
              >
                {ele?.document?.image ? (
                  <div className={`${style.search_img}`}>
                    <img
                      src={ele?.document?.image}
                      height={50}
                      width={50}
                      // loader={() => ele.document.image}
                      // blurDataURL={ele.document.image}
                      placeholder="blur"
                      alt={ele?.document?.image}
                    />
                  </div>
                ) : (
                  ''
                )}
                {(ele.type == 2 || ele.type == 5) && (
                  <span className="pr-2">
                    <SearchIcon iconColor="#939393" />
                  </span>
                )}
                <div
                  className={`block text-sm product_listdata ${style.parent_search}`}
                >
                  {ele.type == 1 && <p>{ele?.document?.name}</p>}
                  {ele.type == 4 && <p>{ele?.document?.model}</p>}
                  <span>
                    {(ele.type == 1 || ele.type == 4) && 'In  '}
                    <span>{ele?.document?.parant_category}</span>
                    {ele?.type == 2 && <span>{ele?.document?.keyword}</span>}
                    {ele?.type == 5 && <span>{ele?.parant_category}</span>}
                  </span>
                </div>
              </Link>
            ))}
          </div>
          <div className={style.close} onClick={() => setShowList(false)}></div>
        </div>
      )}
    </div>
  )
}
