import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { UseNotificationState } from '~/stores/notify'
import { QuickSearchStore } from '~/stores/quick-search'
import { CheveronLeft } from '../../icons/cheveron-left'
import { Close } from '../../icons/close'
import { SearchIcon } from '../../icons/search'
import { PimHomeService } from '~/services/pim.service'
import style from './mobile-search-model.module.scss'
import {
  HorizontalScrollType,
  HorizontalScrollWrapper,
} from '~/components/ui/horizontal-scroll-wrapper/horizontal-scroll-wrapper'
import { ProductCardlist } from '../../cards/cardlist/productcard-list'
import Link from 'next/link'
import Img from '../../img/img'
import { Logger } from '~/util'

export const MobileSearchModel: FC<{ isSmallSearch: boolean }> = (_props) => {
  const searchState = QuickSearchStore.useContainer()
  const [showSearchList, setShowSearchList] = useState(false)
  const [searchbrand, setSearchBrand] = useState([] as any)
  const [searchcategory, setSearchCategory] = useState([] as any)
  const [searchproduct, setSearchProduct] = useState([] as any)
  const [searchmodel, setSearchModel] = useState([] as any)

  const router = useRouter()
  const gotoSearchCategories = (name: any) => {
    router.push({
      pathname: '/s',
      query: {
        catagories: `categories:=[\`${name}\`]`,
      },
    })
    searchState.setModel(false)
  }
  const refEle = useRef<HTMLDivElement>()
  const notify = UseNotificationState.useContainer()
  useLayoutEffect(() => {
    if (_props.isSmallSearch) {
      searchState.searchSuggestion()
    }
  }, [refEle, _props.isSmallSearch])

  const handleClick = (ele: any) => {
    if (ele.type == 1) {
      let link = {
        pathname: '/[slug]/p',
        query: {
          slug: ele.document.slug,
        },
      }
      // if (notify.isStore) {
      //   link.query['store'] = notify.storeName
      // }
      router.push(link)
    } else if (ele.type == 2) {
      let link = {
        pathname: '/s',
        query: {
          q: ele.document.keyword,
        },
      }
      // if (notify.isStore) {
      //   link.query['store'] = notify.storeName
      // }
      router.push(link)
    } else if (ele.type == 4) {
      let link = {
        pathname: `/${ele.document.model_code}`,
      }
      router.push(link)
    } else if (ele.type == 5) {
      let link = {
        pathname: '/s',
        query: {
          catagories: `categories:=${ele.parant_category}`,
        },
      }
      // if (notify.isStore) {
      //   link.query['store'] = notify.storeName
      // }
      router.push(link)
    }
    searchState.setModel(false)
  }

  useEffect(() => {
    if (!searchState.search) {
      setShowSearchList(false)
      searchState.setSearchList([])
    }
  }, [searchState.search])

  const fetchData = async () => {
    try {
      const { data }: any = await PimHomeService.TrendingSearch({
        channel: 'ONLINE',
      })
      setSearchBrand(data.data.brands),
        setSearchCategory(data.data.categories),
        setSearchProduct(data.data.trendingSearch)
      setSearchModel(data.data.trendingProducts)
    } catch (err) {
      Logger.error('Error fetching data:', err)
    }
  }
  useLayoutEffect(() => {
    fetchData()
  }, [])

  return searchState.model ? (
    <div className={style.searchModel} ref={refEle}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          searchState.setModel(false)
          setShowSearchList(true)
          router.push({
            pathname: '/s',
            query: {
              q: searchState.search,
            },
          })
        }}
        className={style.search}
      >
        <button type="button" onClick={() => searchState.setModel(false)}>
          <CheveronLeft />
        </button>
        <label htmlFor="msearch">
          <input
            autoFocus
            autoComplete="off"
            onChange={(e) => {
              let t: any = e.target
              searchState.setSearch(t.value)
              setShowSearchList(!!t.value)
            }}
            value={searchState.search}
            id="msearch"
            type="search"
            placeholder="Search for products..."
          />
        </label>
        {!searchState.search && (
          <button type="submit" disabled>
            <SearchIcon iconColor="#000" />
          </button>
        )}
        {searchState.search && (
          <button type="button" onClick={() => searchState.setSearch('')}>
            <Close iconColor="#000" />
          </button>
        )}
      </form>
      {!showSearchList && (
        <div className={style.models}>
          {searchproduct && searchproduct?.length > 0 && (
            <>
              <div className="flex justify-start">
                <h2 className="text-black-500 ml-3 mb-3 mt-5 font-bold">
                  Trending Search
                </h2>
              </div>
              <div className="flex flex-col">
                <div className="inline-block ml-2">
                  {searchproduct?.map((product: any, index: any) => (
                    <>
                      <Link
                        href={`/s?q=${product}`}
                        target="_self"
                        onClick={() => searchState?.setModel(false)}
                      >
                        <button
                          type="button"
                          key={index}
                          className="text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-2xl text-xs px-3 py-2 ml-2 items-center mt-2"
                        >
                          {product}
                        </button>
                      </Link>
                    </>
                  ))}
                </div>
              </div>
            </>
          )}

          {searchcategory && searchcategory?.length > 0 && (
            <>
              <div className="flex justify-start">
                <h2 className="text-black-500 ml-3 mb-0 mt-5 font-bold">
                  Top Categories
                </h2>
              </div>
              <div className="relative overflow-hidden">
                <div
                  className={`relative overflow-auto p-3 ${style['no-scrollbar']}`}
                >
                  <div
                    className={`${style.topcategories} grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4`}
                  >
                    {searchcategory?.map((category: any, index: any) => (
                      <div
                        key={index}
                        className="flex flex-col items-center mr-4"
                      >
                        <Link
                          href={`/s?categories_slug=categories_slug:=[\`${category?.code}\`]`}
                          target="_self"
                          onClick={() => searchState?.setModel(false)}
                          className="flex flex-col items-center"
                        >
                          <div
                            className={`${style.imagecover} flex justify-center items-center`}
                          >
                            <Img
                              rWidth={{
                                mobileweb: 60,
                                web: 60,
                              }}
                              rHeight={{
                                mobileweb: 90,
                                web: 90,
                              }}
                              deviceType={'mobileweb'}
                              src={
                                category?.image?.url ||
                                'https://img.poorvika.com/common/Noimage.png'
                              }
                              loader={() =>
                                category?.image?.url ||
                                'https://img.poorvika.com/common/Noimage.png'
                              }
                              blurDataURL={
                                category?.image?.url ||
                                'https://img.poorvika.com/common/Noimage.png'
                              }
                              alt={category?.image?.alt}
                              className="object-cover"
                            />
                          </div>
                          <div className="text-gray-600 text-xs text-center font-semibold mt-2">
                            <span className={style.categoriesname}>
                              {category.name}
                            </span>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
          {searchmodel && searchmodel?.length > 0 && (
            <>
              <div className="flex justify-start">
                <h2 className="text-black-500 ml-3 mb-4 mt-0 font-bold	">
                  Trending Products
                </h2>
              </div>
              <>
                <HorizontalScrollWrapper
                  type={HorizontalScrollType.NORMAL}
                  sliderButton
                  products
                  buttoncustom={true}
                >
                  {searchmodel.map((model: any, index: number) => (
                    <ProductCardlist
                      linkPrefix={`${String(model?.name).replace(/ /g, '-')}`}
                      imgHeight="140px"
                      showCart
                      data={model}
                      key={index}
                      onLike={() => {}}
                      mode="mobileweb"
                    />
                  ))}
                </HorizontalScrollWrapper>
              </>
            </>
          )}

          {searchbrand && searchbrand?.length > 0 && (
            <>
              <div className="flex justify-start">
                <h2 className="text-black-500 ml-3 mb-0 mt-3 font-bold">
                  Top Brands
                </h2>
              </div>
              <div className="relative overflow-hidden">
                <div
                  className={`relative overflow-auto p-2 ${style['no-scrollbar']}`}
                >
                  {(() => {
                    const half =
                      searchbrand?.length > 5
                        ? Math.ceil(searchbrand?.length / 2)
                        : searchbrand?.length
                    return (
                      <>
                        <div className="flex flex-nowrap gap-3 w-max">
                          {searchbrand
                            .slice(0, half)
                            .map((brand: any, index: any) => (
                              <>
                                <Link
                                  href={`/s?brand_name=brand_name:=[\`${brand?.name}\`]`}
                                  target="_self"
                                  onClick={() => searchState?.setModel(false)}
                                >
                                  <div className="p-2">
                                    <Img
                                      rWidth={{
                                        mobileweb: 80,
                                        web: 80,
                                      }}
                                      rHeight={{
                                        mobileweb: 80,
                                        web: 80,
                                      }}
                                      deviceType="mobileweb"
                                      src={
                                        brand?.image?.url ||
                                        'https://img.poorvika.com/common/Noimage.png'
                                      }
                                      loader={() =>
                                        brand?.image?.url ||
                                        'https://img.poorvika.com/common/Noimage.png'
                                      }
                                      blurDataURL={
                                        brand?.image?.url ||
                                        'https://img.poorvika.com/common/Noimage.png'
                                      }
                                      alt={brand?.image?.alt}
                                    />
                                  </div>
                                </Link>
                              </>
                            ))}
                        </div>
                        <div className="flex flex-nowrap gap-3 w-max">
                          {searchbrand
                            ?.slice(half)
                            .map((brand: any, index: any) => (
                              <>
                                <Link
                                  href={`/s?brand_name=brand_name:=[\`${brand?.name}\`]`}
                                  target="_self"
                                  onClick={() => searchState?.setModel(false)}
                                >
                                  <div className="p-2">
                                    <Img
                                      rWidth={{
                                        mobileweb: 80,
                                        web: 80,
                                      }}
                                      rHeight={{
                                        mobileweb: 80,
                                        web: 80,
                                      }}
                                      deviceType={'mobileweb'}
                                      src={
                                        brand?.image?.url ||
                                        'https://img.poorvika.com/common/Noimage.png'
                                      }
                                      loader={() =>
                                        brand?.image?.url ||
                                        'https://img.poorvika.com/common/Noimage.png'
                                      }
                                      blurDataURL={
                                        brand?.image?.url ||
                                        'https://img.poorvika.com/common/Noimage.png'
                                      }
                                      alt={brand?.image?.alt}
                                    />
                                  </div>
                                </Link>
                              </>
                            ))}
                        </div>
                      </>
                    )
                  })()}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <div>
        {showSearchList && (
          <>
            {searchState?.loading ? (
              <div className="py-2 px-2 text-center">
                <b>loading...</b>
              </div>
            ) : (
              !searchState.found && (
                <div className="py-2 px-2 text-center">
                  <b>No items found</b>
                </div>
              )
            )}
            <ul className={`w-1/12 ${style.searchUl}`}>
              {searchState.searchList.map((ele: any, i: number) => (
                <li
                  key={i}
                  className="p-2 flex"
                  onClick={() => handleClick(ele)}
                >
                  {ele.type == 1 && ele.document.thumb ? (
                    <div
                      style={{
                        width: '50px',
                        position: 'relative',
                        objectFit: 'contain',
                      }}
                    >
                      <img
                        className={style.image}
                        src={ele.document.thumb}
                        alt="document"
                        //layout="fill"
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {ele.type == 4 && ele?.document?.image ? (
                    <div className={`${style.search_img}`}>
                      <img
                        src={ele?.document?.image}
                        height={50}
                        width={50}
                        // loader={() => ele.document.image}
                        // blurDataURL={ele.document.image}
                        placeholder="blur"
                        alt={ele?.document?.image}
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  {(ele.type == 2 || ele.type == 5) && (
                    <div className="mr-2">
                      <SearchIcon iconColor="#000" strokeWidth={1} />
                    </div>
                  )}
                  <div
                    className={`pl-1 block text-xs product_listdata ${style.parent_search}`}
                  >
                    {ele.type == 1 && <p>{ele?.document?.name}</p>}
                    {ele.type == 4 && <p>{ele?.document?.model}</p>}
                    <span>
                      {(ele.type == 1 || ele.type == 4) && 'In  '}
                      <span>{ele?.document?.parant_category}</span>
                      {ele?.type == 2 && <span>{ele?.document?.keyword}</span>}
                      {ele?.type == 5 && <span>{ele?.parant_category}</span>}
                    </span>
                  </div>
                </li>
              ))}
              {searchState.found > 20 ? (
                <li className={`${style.view_all_mobile}`}>
                  <div
                    onClick={() => {
                      router.push({
                        pathname: '/s',
                        query: {
                          q: searchState.search,
                        },
                      })
                      searchState.setModel(false)
                    }}
                  >
                    View All
                  </div>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}
