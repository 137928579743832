import Link from 'next/link'
import { FC, useEffect, useRef, useState } from 'react'
import Img from '~/components/ui/img/img'
import { PriceFormat, IsMobile } from '~/util'
import { FavouriteButton } from '../../favourite-button/favourite-button'
import style from './product-cardlist.module.scss'
import { Star } from '../../icons/star'
import { mrpPrice, sellingPrice } from '~/util/price'
import { QuickSearchStore } from '~/stores/quick-search'
import { ProductCardListProps } from '../product-cardlist/product-cardlist.interface'

/**
 * product card renders Product card this will have fav icon and add to card button
 * @category Components
 * @subcategory ProductCardlist
 * @param {ProductCardListProps} props product card related data
 * @returns {JSX.Element} jsx value
 */
export const ProductCardlist: FC<ProductCardListProps> = (props: any) => {
  const searchState = QuickSearchStore.useContainer()
  const product_layout = props.prodstyl
  const [target, setTarget] = useState('_blank')
  const ref = useRef(null)
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
    if (ref.current) {
      ref.current.style.color = '#3b3b3b'
      ref.current.style.fontSize = '11px'
      ref.current.style.fontWeight = '500'
      ref.current.style.paddingLeft = '8px'
    }
  }, [])

  return (
    <div>
      <div
        className={` ${
          product_layout == 'VERTICAL_MEDIUMTHREE'
            ? `${style.product_layout}`
            : 'border'
        } ${
          product_layout == 'HORIZONTAL_MEDIUMTHREE' || 'SMALL'
            ? `${style.image_styles}`
            : ''
        }`}
      >
        <div
          className={`${
            product_layout == 'VERTICAL_MEDIUMTHREE' ||
            product_layout == 'HORIZONTAL_MEDIUMTHREE'
              ? 'hidden'
              : 'block'
          } ${style.heart_icon}`}
        >
          <FavouriteButton
            item_code={props.data.item_code}
            slug={props.data.code}
          />
        </div>
        <Link
          href={link}
          target={target}
          style={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '15px 0px',
          }}
          onClick={() => searchState?.setModel(false)}
        >
          <Img
            rWidth={{
              mobileweb: 150,
              web: 150,
            }}
            rHeight={{
              mobileweb: 150,
              web: 150,
            }}
            deviceType={props.mode}
            src={`${
              props.data.thumb
                ? props.data.thumb
                : props.data.image
                ? props.data.image
                : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
            }`}
            alt={props?.data?.image?.alt}
            blurDataURL={`${
              props.data.thumb
                ? props.data.thumb
                : props.data.image
                ? props.data.image
                : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
            }`}
            placeholder="blur"
          />
        </Link>
        <div className={`${style.prop_rel}`}>
          <div className={style.pos_abs_desc}>{props.data.name}</div>
          <Link
            href={link}
            target={target}
            onClick={() => searchState?.setModel(false)}
          >
            <div>
              <h3>{props.data.name}</h3>
              <h4
                style={{
                  visibility: `${
                    props.data.option_value ? 'visible' : 'hidden'
                  }`,
                }}
              >
                {props.data.option_value}
              </h4>

              <div className={`${style.card_top}`}>
                <h5
                  className={
                    product_layout == 'HORIZONTAL_MEDIUMTHREE'
                      ? `${style.horizontalthree_price}`
                      : ''
                  }
                >
                  {sellingprice?.length != 0
                    ? PriceFormat(sellingprice[0]?.price)
                    : PriceFormat(mrpprice[0]?.price)}
                </h5>
                <div>
                  {!!props.data.rating && props.data.review_count > 0 && (
                    <p
                      className={`${
                        product_layout == 'HORIZONTAL_MEDIUMTHREE'
                          ? `${style.horizontalthree_price}`
                          : ''
                      } ${'flex items-center'}`}
                    >
                      <Star color="#f46b27" type="FILL" />
                      <b style={{ color: 'black' }}>{props.data.rating}</b>
                      <span className="ml-2">({props.data.review_count})</span>
                    </p>
                  )}
                </div>
              </div>
              <div className="flex mt-3">
                <span className="text-xs line-through text-gray-500">
                  {Math.round(
                    100 -
                      (Number(sellingprice[0]?.price) /
                        Number(mrpprice[0]?.price)) *
                        100
                  ) > 0 &&
                    (Number(mrpprice[0]?.price) >=
                    Number(sellingprice[0]?.price)
                      ? PriceFormat(mrpprice[0]?.price)
                      : PriceFormat(sellingprice[0]?.price))}
                </span>
                <span className="text-xs text-gray-700 pl-2 font-semibold">
                  {Math.round(
                    100 -
                      (Number(sellingprice[0]?.price) /
                        Number(mrpprice[0]?.price)) *
                        100
                  ) > 0 && (
                    <span>
                      {Math.round(
                        100 -
                          (Number(sellingprice[0]?.price) /
                            Number(mrpprice[0]?.price)) *
                            100
                      )}
                      % OFF
                    </span>
                  )}
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
