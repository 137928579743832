import style from './text-input.module.scss'

export interface TextInputProps {
  id: string
  name?: string
  value: string
  className?: string
  type?: 'text' | 'email' | 'number' | 'password' | 'textarea'
  label?: string
  placeHolder?: string
  validation?: RegExp
  rows?: number
  maxLength?: number
  onChange: (value: string) => void
}

export const TextInput = ({
  id,
  name,
  value,
  onChange,
  className,
  type,
  label,
  placeHolder,
  validation,
  rows,
  maxLength,
}: TextInputProps) => (
  <div className={`${style.wrapper} ${className}`}>
    {type !== 'textarea' ? (
      <input
        type={type || 'text'}
        id={id}
        name={name}
        value={value}
        required
        placeholder={placeHolder}
        maxLength={maxLength}
        onInput={({ target }: any) => {
          if (validation) {
            if (validation.test(target.value)) {
              onChange(target.value)
            } else {
            }
          } else {
            onChange(target.value)
          }
        }}
      />
    ) : (
      <textarea
        id={id}
        name={name}
        value={value}
        required
        rows={rows}
        placeholder={placeHolder}
        maxLength={maxLength}
        onInput={({ target }: any) => {
          if (validation) {
            if (validation.test(target.value)) {
              onChange(target.value)
            } else {
            }
          } else {
            onChange(target.value)
          }
        }}
      />
    )}

    {label ? <label htmlFor={id}>{label}</label> : ''}
  </div>
)
