import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect, useRef } from 'react'
import { Config } from '~/config/config'
import { AuthState } from '~/stores/auth'
import { LanguageState } from '~/translations/computation'
import { useMounted } from '~/util/hooks'
import { NotifyWrapper } from '../ui/notification/notification'

/**
 *
 * this is a wrapper component for whole website
 * used to initialize all the state and session related stuffs in here
 * @category Wrapper
 * @subcategory Middleware
 * @param {JSX.Element} param0 fgd sdfgd
 * @returns {JSX.Element} JSX Html page wrapped around common and configuration elements
 */
export const AppWrapper: FC = ({ children }) => {
  const language = LanguageState.useContainer()
  const authState = AuthState.useContainer()
  const parentRef = useRef(null)

  useMounted(
    parentRef,
    () => {
      language.init()
      updateUserData()
    },
    []
  )

  const updateUserData = async () => {
    try {
      await authState.initUserData()
    } catch (err) {}
  }

  const router = useRouter()
  useMounted(
    parentRef,
    () => {
      const whitelist = [
        '/s',
        '/cart',
        '/cart/bfl',
        '/[slug]/p',
        '/cart/pay',
        '/account-mobile',
      ]
      if (!whitelist.includes(router.pathname)) {
        if (!!Cookies.get('store')) {
          Cookies.remove('store')
          if (window) {
            window.location.reload()
          }
        }
      }
    },
    [router]
  )

  return (
    <div ref={parentRef}>
      <NotifyWrapper />
      {children}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${Config.GOOGLE_TAG_MANAGER}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </div>
  )
}
