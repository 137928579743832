import { createContainer } from 'unstated-next'
import cookie from 'js-cookie'
import { useState } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { Lang } from '@/translations/LangKeys'

const useLang = (initialState: any) => {
  let [lang, setLang] = useState(initialState as Lang | string)
  const router = useRouter()
  const updateLang = (val: Lang) => {
    setLang(val)
    cookie.set('lang', val)
  }
  const init = () => {
    const localData = Cookies.getJSON()
    if (localData) {
      if (localData.lang) {
        updateLang(localData.lang)
        // router.locale = localData['lang']
        if (localData['lang'] != router.locale) {
          router.push(
            {
              pathname: router.pathname,
              query: router.query,
            },
            '',
            {
              locale: localData.lang,
            }
          )
        }
      }
    }
  }

  const switchLang = (e: any) => {
    const language: any = e.target.value
    updateLang(language)
    router.replace(
      {
        pathname: router.pathname,
        query: router.query,
      },
      '',
      { locale: language }
    )
  }

  return {
    lang,
    updateLang,
    init,
    switchLang,
  }
}

export const LanguageState: any = createContainer(useLang)
