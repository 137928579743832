import axios from 'axios'
import moment from 'moment'
import { RegxUtil } from './regx'

export const DateFormat = (date: string, formateString: string) =>
  moment(date).format(formateString)

export const TrackFormat = (date: string, formateString: string) => {
  const utcDateTimes = moment.utc(date, 'YYYY-MM-DD HH:mm')
  const IstDateTimes = utcDateTimes.utcOffset(0).format(formateString)
  return IstDateTimes
}

const getOffset = () => {
  let offset = new Date().getTimezoneOffset()
  let addPrefix = (v) => {
    let va = String(Math.abs(v))
    return va.length > 1 ? va : `0${va}`
  }
  let result =
    (offset > 0 ? '-' : '+') +
    addPrefix(~~(offset / 60)) +
    ':' +
    (Math.abs(offset) % 60)
  return result
}
export const DateFormatWithTimeAdjust = (
  date: string,
  formateString: string
) => {
  const utcDateTime = moment.utc(date, 'YYYY-MM-DD HH:mm')
  const IstDateTime = utcDateTime.utcOffset(getOffset()).format(formateString)

  return IstDateTime
}

export const PriceFormat = (price: any) => {
  if (/\-/.test(price)) {
    return ''
  }
  let pr = Number(price)
    .toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
    })
    .replace('.00', '')
  if (/[0-9]/g.test(pr)) {
    return `${pr[0]} ${pr.slice(1, pr.length)}`
  }
  return ''
}

export const IsMobile = () => {
  try {
    return /Mobi|Android/i.test(navigator.userAgent)
  } catch (e) {
    return false
  }
}
export const IsMobileNumber = (val: any) => RegxUtil.MobileNumber.test(val)
export const IsEmail = (val: any) => RegxUtil.Email.test(val)
export const Logger = {
  /**
   * this will be logged on server side on production
   * @param val value
   * @returns void
   */
  error: (...err: any) =>
    process.env.NODE_ENV == 'development'
      ? console.error(...err)
      : axios.post('/api/logs/error', { error: [...err] }).catch(console.error),
  /**
   * this will be logged only on development environment
   * @param val value
   * @returns void
   */
  log: (...val: any) =>
    process.env.NODE_ENV == 'development' ? console.log(...val, 'val') : '',
  /**
   * this will be logged on server side on production
   * @param val value
   * @returns void
   */
  info: (...val: any) =>
    process.env.NODE_ENV == 'development'
      ? console.info(...val)
      : axios.post('/api/logs/info', { info: [...val] }).catch(console.error),
}
