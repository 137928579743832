import style from '../app-bar.module.scss'
import { LanguageState } from '~/translations/computation'
import { AppBarText } from '../app-bar.translations'
import { ChevronDownWhite } from '../../icons/chevron-down-white'

/**
 * app bar contains basic locate stores
 * @returns {JSX.Element} returns app bar contains basic locate stores
 */
export const AppBarLocateStore = () => {
  const lang = LanguageState.useContainer()
  return (
    <div>
      <div className={`lg:float-right`}>
        <div className={`${style.caption}`}>{AppBarText.Locate[lang.lang]}</div>
        <div className={`flex ${style.name} ${style.store_wrapper}`}>
          <button className={`font-semibold`}>
            {AppBarText.Stores[lang.lang]}
          </button>

          <ChevronDownWhite />
        </div>
      </div>
    </div>
  )
}
