import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { Button } from '~/components/ui/button/button'
import { LanguageState } from '~/translations/computation'
import { FormInput } from '../../form-input/form-input'
import { LoginRegisterFormText } from '../login-register-form.translation'
import style from '../login-register-form.module.scss'
import { FormProps } from './form.interface'
import { RegxUtil } from '~/util/regx'
import { AuthState } from '~/stores/auth'
import { Counter } from './counter'
import OtpTimer from 'otp-timer'

import { IsEmail, IsMobileNumber, Logger } from '~/util'
import { UseNotificationState } from '~/stores/notify'
const OtpInput: any = dynamic(() => import('react-otp-input'))

export const Form: FC<FormProps> = (props) => {
  const lang = LanguageState.useContainer()
  const auth = AuthState.useContainer()
  const [count, setCount] = useState(120)
  const [loading, setLoading] = useState(false)
  const formRef = useRef<HTMLFormElement | null>(null)
  const notify = UseNotificationState.useContainer()
  const titleText: any = {
    PROFILE: 'Profile',
    LOGIN: LoginRegisterFormText.SignIn,
    REGISTER: LoginRegisterFormText.SignUP,
    FORGET: 'Forgot Password',
    OTPVALUE: LoginRegisterFormText.EnterOtp,
    VERIFY_OTP: LoginRegisterFormText.UpdatePasswordWithOtp,
    WITH_OTP: 'Login with OTP',
    FORGOT_PWD_OTP: '',
  }

  const buttonText: any = {
    LOGIN: () => (props.accountActive ? 'LOGIN' : 'Activate Account'),
    REGISTER: () => 'REGISTER',
    FORGET: () => 'Verify OTP',
    OTPVALUE: () => LoginRegisterFormText.OTP,
    VERIFY_OTP: () => LoginRegisterFormText.OTP,
    PROFILE: () => 'Update Profile',
    UPDATE: () => 'Update Password',
    WITH_OTP: () => 'Verify OTP',
    FORGOT_PWD_OTP: () => '',
    REGISTER_OTP: () => '',
    UPDATE_PWD: () => 'Set Password',
    LOGIN_WITH_OTP: () => 'Submit',
    FORGOT_PWD: () => 'Submit',
  }

  useEffect(() => {
    if (
      ['REGISTER_OTP', 'FORGET', 'WITH_OTP', 'FORGOT_PWD_OTP'].includes(
        props.variant
      )
    ) {
      if (String(props.otp).length == 6 && /^[\d]{6}$/.test(props.otp)) {
        props.onSubmit()
      }
    }
    return () => {}
  }, [props.otp])
  useEffect(() => {
    window.addEventListener('keyup', (e) => {
      if (e.code == 'Escape') {
        notify.removeNotify()
      }
    })
    window.addEventListener('click', (e) => {
      notify.removeNotify()
    })
  }, [])
  const userDataInput = () =>
    ['LOGIN', 'REGISTER', 'FORGOT_PWD', 'LOGIN_WITH_OTP'].includes(
      props.variant
    ) ? (
      <FormInput
        id="userData"
        required={true}
        label={LoginRegisterFormText.UserData[lang.lang]}
        placeholder={LoginRegisterFormText.UserData[lang.lang]}
        type="text"
        value={props.userdata}
        onChange={(val: any) => props.updateField('userdata', val)}
      />
    ) : (
      ''
    )

  const userNameInput = () =>
    ['PROFILE'].includes(props.variant) ? (
      <FormInput
        id="username"
        label={
          props.variant == 'LOGIN'
            ? 'Email / Phone Number'
            : LoginRegisterFormText.UserName[lang.lang]
        }
        placeholder={LoginRegisterFormText.UserName[lang.lang]}
        type="text"
        value={props.username}
        required
        onChange={(val: any) =>
          props.updateField('username', val.replace(/[^a-zA-Z .]/g, ''))
        }
        patterns={[
          {
            reg: RegxUtil.OnlyStrings,
            message: 'Name is Required',
          },
        ]}
      />
    ) : (
      ''
    )

  const mobileNumber = () =>
    ['PROFILE'].includes(props.variant) ? (
      <FormInput
        id="mobileno"
        label={LoginRegisterFormText.MobileNo[lang.lang]}
        placeholder={LoginRegisterFormText.MobileNo[lang.lang]}
        type="text"
        value={`${/^\d+$/.test(`${props.mobileno}`) ? props.mobileno : ''}`}
        onChange={(val: any) => props.updateField('mobileno', val)}
        required
        disabled={props.registeredWIth == 'mobileno'}
        maxLength={10}
        patterns={[
          {
            reg: RegxUtil.Mobiles,
            message: 'Mobile Number is Required',
          },
        ]}
      />
    ) : (
      ''
    )

  const emailInput = () =>
    ['PROFILE'].includes(props.variant) ? (
      <FormInput
        id="email"
        label={LoginRegisterFormText.Email[lang.lang]}
        placeholder={LoginRegisterFormText.Email[lang.lang]}
        type="email"
        value={props.email}
        required
        disabled={props.registeredWIth == 'email'}
        onChange={(val: any) => props.updateField('email', val)}
        patterns={[
          {
            reg: RegxUtil.Email,
            message: 'Email is Required',
          },
        ]}
      />
    ) : (
      ''
    )

  const passwordInput = () =>
    ['LOGIN', 'PROFILE', 'VERIFY_OTP', 'UPDATE', 'UPDATE_PWD'].includes(
      props.variant
    ) ? (
      <FormInput
        id="password"
        label={props.variant != 'UPDATE_PWD' ? 'Password' : 'Change Password'}
        placeholder={LoginRegisterFormText.Password[lang.lang]}
        type="password"
        showEye
        value={props.password}
        onChange={(val: any) => props.updateField('password', val)}
        maxLength={16}
        required
      />
    ) : (
      ''
    )

  const minutes = useMemo(() => {
    return ~~(props.timeLeft / 60)
  }, [props.timeLeft])

  const seconds = useMemo(() => {
    return props.timeLeft % 60
  }, [props.timeLeft])

  const otpInput = () =>
    ['REGISTER_OTP', 'FORGET', 'WITH_OTP', 'FORGOT_PWD_OTP'].includes(
      props.variant
    ) ? (
      <div className="pb-2 mx-auto text-center">
        <OtpInput
          value={`${/^\d+$/.test(props.otp) ? props.otp : ''}`}
          isInputNum={true}
          onChange={(otp: any) => {
            const re = /^[0-9\b]+$/
            if (otp === '' || re.test(otp)) {
              props.updateField('otp', otp)
            }
          }}
          numInputs={6}
          shouldAutoFocus={true}
          autocomplete="one-time-code"
          className={`${style.otp_input}`}
        />
        <span className={style.otp_text}>
          An OTP has been sent to your{' '}
          <span style={{ color: '#ff6517', fontWeight: 600 }}>
            {props.userdata}
          </span>{' '}
          please enter the number to continue
        </span>
        <div className={`flex justify-end ${style.resend_btn}`}>
          {props.timeLeft == 0 && (
            <button
              className={style.resend_btn}
              type="button"
              onClick={() => {
                if (props.variant === 'REGISTER_OTP') {
                  props.onRequestOTP(props.variant)
                } else {
                  props.onRequestOTP(props.variant)
                }
              }}
            >
              Resend OTP
            </button>
          )}
          {props.timeLeft > 0 ? (
            <span>
              {/* <Counter setCount={setCount} count={count} /> */}
              <OtpTimer
                seconds={seconds}
                minutes={minutes}
                resend={() => {
                  if (props.variant === 'REGISTER_OTP') {
                    props.onRequestOTP(props.variant)
                  } else {
                    props.onRequestOTP(props.variant)
                  }
                }}
              />
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    ) : (
      ''
    )

  return (
    <form
      className={`${style.main_form}`}
      onSubmit={(e) => {
        e.preventDefault()
        props.onSubmit()
      }}
      ref={formRef}
    >
      <h3 className="capitalize mt-4 mb-4 text-center text-lg font-medium text-black">
        {!!titleText[props.variant] && titleText[props.variant][lang.lang]}
      </h3>
      {props.variant == 'LOGIN' || props.variant == 'REGISTER' ? (
        <p className="capitalize mt-4 mb-4 text-center text-sm font-medium text-gray-500">
          {LoginRegisterFormText.ToAccessYourOrderEasily[lang.lang]}
        </p>
      ) : (
        ''
      )}
      {props.variant == 'OTPVALUE' ? (
        <p className="capitalize mt-4 mb-4 text-center text-sm font-medium text-gray-500">
          {LoginRegisterFormText.Otpvalues[lang.lang]}
        </p>
      ) : (
        ''
      )}
      {props.variant == 'OTPVALUE' ? (
        <p className="capitalize mt-4 mb-4 text-center text-sm font-medium text-gray-500">
          {LoginRegisterFormText.TimeDuration[lang.lang]}
        </p>
      ) : (
        ''
      )}
      {userNameInput()}
      {mobileNumber()}
      {emailInput()}
      {userDataInput()}
      {otpInput()}
      {passwordInput()}
      {props.variant == 'LOGIN' ? (
        <div className={`${style.form_options}`}>
          <span
            className={style.form_options__register}
            onClick={() => props.onChangeVariant('REGISTER')}
          >
            Register
          </span>
          <span
            onClick={() => {
              props.onChangeVariant('FORGOT_PWD')
            }}
          >
            Forgot Password ?
          </span>
        </div>
      ) : (
        ''
      )}

      <div className="pt-6 " style={{}}>
        {buttonText[props.variant]() ? (
          <Button
            variant="ORANGE_GRADIENT"
            type="submit"
            spinnerWidth={3}
            spinnerThickness={3}
            loading={props.otpLoader}
            disabled={props.otpLoader}
            onClick={() => {}}
          >
            {buttonText[props.variant]()}
          </Button>
        ) : (
          ''
        )}
        {props.variant == 'REGISTER' ? (
          <Button
            onClick={() => props.onChangeVariant('LOGIN')}
            variant="NORMAL"
            className="py-3 px-4 text-center w-full drop-shadow bg-white mt-3"
          >
            {'LOGIN'}
          </Button>
        ) : (
          ''
        )}
        {props.variant == 'LOGIN' ? (
          <span
            className="text-center block mt-3 font-medium text-lg"
            style={{ color: '#ff6c00' }}
          >
            or
          </span>
        ) : (
          <></>
        )}
        {props.variant == 'LOGIN' ? (
          <Button
            spinnerWidth={3}
            spinnerThickness={3}
            loading={loading}
            disabled={loading}
            onClick={() => {
              setLoading(true)
              const condition =
                IsMobileNumber(props.userdata) || IsEmail(props.userdata)

              if (condition) {
                setTimeout(() => {
                  setLoading(false)
                }, 3000)
                props.onRequestOTP(props.variant)
              } else {
                setTimeout(() => {
                  setLoading(false)
                }, 3000)
                props.onChangeVariant('LOGIN_WITH_OTP')
              }
            }}
            variant="NORMAL"
            className="py-3 px-4 text-center w-full drop-shadow bg-white"
          >
            LOGIN WITH OTP / GUEST
          </Button>
        ) : (
          ''
        )}
        {['WITH_OTP', 'FORGOT_PWD_OTP'].includes(props.variant) && (
          <Button
            onClick={() => props.onChangeVariant('LOGIN')}
            variant="NORMAL"
            className="py-3 px-4 text-center w-full drop-shadow bg-white mt-3"
          >
            Back To Login
          </Button>
        )}
      </div>
      {props.variant == 'LOGIN' ? (
        <div className={`${style.bottom_content}`}>
          <p
            style={{
              fontSize: '10px',
            }}
          >
            <span>Upon login, I agree to Poorvika&apos;s </span>
            <button onClick={() => auth.setShowLoginForm(false)}>
              <Link
                href="/privacy-policy"
                passHref
                style={{ color: '#2874F0' }}
              >
                {' '}
                Privacy Policy
              </Link>
            </button>
            <span> &amp; </span>
            <button onClick={() => auth.setShowLoginForm(false)}>
              <Link
                href="/terms-and-conditions"
                passHref
                style={{ color: '#2874F0' }}
              >
                {' '}
                Terms
              </Link>
            </button>
          </p>
        </div>
      ) : (
        ''
      )}
    </form>
  )
}
