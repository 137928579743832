import { FC, useRef, useState } from 'react'
import Link from 'next/link'
import { Accordion } from '~/components/ui/accordion/accordion'
import { LastChild } from '../lastchild'
import { MobileChildProps } from './subchildmobile.interface'
import { Plus } from '~/components/ui/icons/plus'
import { Minus } from '~/components/ui/icons/minus'
import { useMounted } from '~/util/hooks'
import { QuickSearchStore } from '~/stores/quick-search'

/**
 * this is our custom mobile menu component
 * @returns {JSX.Element} this will return a mobile menu element
 */
export const SubChildMobileMenu: FC<MobileChildProps> = (props) => {
  const searchState = QuickSearchStore.useContainer()
  const [modifiedData, setModifiedData] = useState([] as any)
  const [active, setActive] = useState(-1)
  const parentRef = useRef(null)
  useMounted(
    parentRef,
    () => {
      const modified = props.data.map((element: any) => [
        element.name,
        element.children,
        element.category_id,
        `categories.lvl1:=[\`${props.parent} > ${element.name}\`]`,
        element.colorCode,
        element.isHighlightable,
        element?.linkParams?.params ? element?.linkParams?.params : [],
        `${props.parent} > ${element.name}`,
      ])
      setModifiedData(modified)
    },
    [props]
  )

  const toggleAccordion = (index: number) => {
    setActive(active === index ? -1 : index)
  }
  const linkQuery = (path) => {
    if (!!path?.pageCode && !!path?.pageType) {
      return {
        pathname: `/[slug]/page`,
        query: {
          type: path?.pageType?.toLowerCase(),
          slug: path?.pageCode,
        },
      }
    } else {
      return '/'
    }
  }
  const headerLink = (name: string, data: any, slug: any) => {
    let objData: any = data?.find((e) => e.isDefault == true)
    if (objData?.isClassic) {
      switch (objData?.linkType.toUpperCase()) {
        case 'EXTERNALLINK':
          return objData?.externalUrl
        case 'PAGE':
          switch (objData?.pageType?.toUpperCase()) {
            case 'GENERAL':
              return linkQuery(objData)
            case 'CATEGORY':
              return linkQuery(objData)
            case 'BRAND':
              return linkQuery(objData)
            case 'OFFER':
              return linkQuery(objData)
          }
        default:
          return '/'
      }
    } else {
      return {
        pathname: `/s`,
        query: {
          categories: slug,
          // stock_status: `stock_status:=[\`In Stock\`]`,
        },
      }
    }
  }
  return (
    <div ref={parentRef}>
      <Accordion
        variant="SINGLE"
        noIndent
        selected={active}
        customHeaders
        className=""
      >
        {modifiedData.map(
          (ele: any, index: number) =>
            ele[2] != 0 && [
              <div key={index}>
                {!!ele[1].length ? (
                  <div
                    className={`flex justify-between items-center w-full pl-6 pr-4 py-2 border-gray-300 border-b text-sm `}
                    key={`item-${index}`}
                  >
                    <Link
                      href={headerLink(ele[0], ele[6], ele[3])}
                      key={index}
                      onClick={() => {
                        props.close(),
                          searchState.categorymobilelink(
                            modifiedData,
                            ele[3],
                            false
                          )
                      }}
                    >
                      <span className="items-center truncate block">
                        <span
                          className="block truncate"
                          style={{ color: `${ele[5] ? `${ele[4]}` : '#000'}` }}
                        >
                          {ele[0]}
                        </span>
                      </span>
                    </Link>
                    <button onClick={() => toggleAccordion(index)}>
                      {active === index ? <Minus /> : <Plus />}
                    </button>
                  </div>
                ) : (
                  <Link
                    href={headerLink(ele[0], ele[6], ele[3])}
                    key={index}
                    className="flex w-full pl-6 pr-4 py-2 items-center text-sm text-gray-800 border-gray-300 border-b"
                  >
                    <span
                      className="block truncate"
                      style={{ color: `${ele[5] ? `${ele[4]}` : '#000'}` }}
                    >
                      {ele[0]}
                    </span>
                  </Link>
                )}
              </div>,

              !!ele[1] && (
                <div key={index}>
                  <LastChild
                    data={ele[1]}
                    close={props.close}
                    parent={props.parent}
                    subparent={ele[0]}
                  />
                </div>
              ),
            ]
        )}
      </Accordion>
    </div>
  )
}
