import { FC } from 'react'
import { CheveronRight } from '../icons/cheveron-right'
import { ChevronDownWhite } from '../icons/chevron-down-white'
import { Plus } from '../icons/plus'
import { useAccordion } from './accordion.hooks'
import { AccordionProps } from './accordion.interface'
import style from './accordion.module.scss'

export const Accordion: FC<AccordionProps> = (props) => {
  const { selected, updateSelected } = useAccordion(props) as any

  const header = (ele: any, i: number) =>
    props.customHeaders ? (
      <div key={i}>{ele[0]}</div>
    ) : (
      <div
        className={`${
          props.styleClass ? props.styleClass : style.accordion__header
        } ${selected.includes(i) ? style['accordion__header--active'] : ''}`}
        onClick={() => updateSelected(i, props.variant)}
        key={`head-${i}`}
      >
        <span style={{ flex: 1 }}>{ele[0]}</span>
        <span>
          {!selected.includes(i) ? <CheveronRight /> : <ChevronDownWhite />}
        </span>
      </div>
    )

  const content = (ele: any, i: number) => (
    <div
      className={props.gap ? '' : style.accordion__content}
      style={{
        paddingLeft: props.noIndent ? '0px' : '25px',
        display: selected.includes(i) ? 'block' : 'none',
      }}
      key={`content-${i}`}
    >
      {ele[1]}
    </div>
  )

  return (
    <div
      className={`${style.accordion} ${props.className}`}
      style={{
        border: props.gap ? 'none' : '1px solid rgba(128, 128, 128, 0.308)',
      }}
    >
      {props.children.map((ele: any, i: any) => [
        header(ele, i),
        content(ele, i),
      ])}
    </div>
  )
}
