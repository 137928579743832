import { FC } from 'react'

type StarProp = {
  type: 'FILL' | 'EMPTY'
  size?: number
  color?: string
}

export const Star: FC<StarProp | any> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth={1}
    stroke={props.type == 'FILL' ? props.color : '#C6C6C6'}
    style={{
      width: `${props.size * 20}px`,
      height: `${props.size * 20}px`,
      fill: props.color,
      borderRadius: '20',
    }}
    className={` mx-1 w-4 h-4  fill-current ${
      props.type == 'FILL'
        ? props.color
          ? props.color
          : 'text-green-400'
        : 'text-gray-400'
    }`}
    // xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
  </svg>
)

export default Star
