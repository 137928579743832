import { FC, useRef, useState } from 'react'
import Link from 'next/link'
import { Accordion } from '~/components/ui/accordion/accordion'
import { LastChildProps } from './lastchild.interface'
import { Plus } from '~/components/ui/icons/plus'
import { Minus } from '~/components/ui/icons/minus'
import { useMounted } from '~/util/hooks'
import { QuickSearchStore } from '~/stores/quick-search'

/**
 * this is our custom mobile menu component
 * @returns {JSX.Element} this will return a mobile menu element
 */
export const LastChild: FC<LastChildProps> = (props) => {
  const searchState = QuickSearchStore.useContainer()
  const [modifiedData, setModifiedData] = useState([] as any)
  const parentRef = useRef(null)
  useMounted(
    parentRef,
    () => {
      const modified = props.data.map((element: any) => [
        element.name,
        element.children,
        element.category_id,
        `categories.lvl2:=[\`${props.parent} > ${props.subparent} > ${element.name}\`]`,
        element.colorCode,
        element.isHighlightable,
        element?.linkParams?.params ? element?.linkParams?.params : [],
        `${props.parent} > ${props.subparent} > ${element.name}`,
      ])
      setModifiedData(modified)
    },
    [props]
  )

  const [active, setActive] = useState(-1)
  const toggleAccordion = (index: number) => {
    setActive(active === index ? -1 : index)
  }
  const linkQuery = (path) => {
    if (!!path?.pageCode && !!path?.pageType) {
      return {
        pathname: `/[slug]/page`,
        query: {
          type: path?.pageType.toLowerCase(),
          slug: path?.pageCode,
        },
      }
    } else {
      return '/'
    }
  }
  const headerLink = (name: string, data: any, slug: any) => {
    let objData: any = data?.find((e) => e.isDefault == true)
    if (objData?.isClassic) {
      switch (objData?.linkType.toUpperCase()) {
        case 'EXTERNALLINK':
          return objData?.externalUrl
        case 'PAGE':
          switch (objData?.pageType?.toUpperCase()) {
            case 'GENERAL':
              return linkQuery(objData)
            case 'CATEGORY':
              return linkQuery(objData)
            case 'BRAND':
              return linkQuery(objData)
            case 'OFFER':
              return linkQuery(objData)
          }
        default:
          return '/'
      }
    } else {
      return {
        pathname: `/s`,
        query: {
          categories: slug,
          // stock_status: `stock_status:=[\`In Stock\`]`,
        },
      }
    }
  }
  return (
    <div>
      <Accordion
        variant="SINGLE"
        noIndent
        selected={active}
        customHeaders
        className=""
      >
        {modifiedData.map((ele: any, index: number) => [
          <span key={index}>
            {ele[1].length ? (
              <div
                className={`flex justify-between items-center w-full pl-12 pr-4 py-2 border-gray-300 border-b text-sm `}
                key={`item-${index}`}
              >
                <Link
                  href={headerLink(ele[0], ele[6], ele[3])}
                  key={index}
                  onClick={() => {
                    props.close(),
                      searchState.categorymobilelink(modifiedData, ele[3])
                  }}
                >
                  <span className="items-center truncate block">
                    <span
                      className="block truncate"
                      style={{ color: `${ele[5] ? `${ele[4]}` : '#000'}` }}
                    >
                      {ele[0]}
                    </span>
                  </span>
                </Link>
                <button onClick={() => toggleAccordion(index)}>
                  {active === index ? <Minus /> : <Plus />}
                </button>
              </div>
            ) : (
              <Link
                href={headerLink(ele[0], ele[6], ele[3])}
                key={index}
                onClick={() => {
                  props.close(),
                    searchState.categorymobilelink(modifiedData, ele[3])
                }}
                className="flex w-full pl-12 pr-4 py-2 border-gray-300 border-b items-center text-sm"
              >
                <span
                  className="block truncate"
                  style={{ color: `${ele[5] ? `${ele[4]}` : '#000'}` }}
                >
                  {ele[0]}
                </span>
              </Link>
            )}
          </span>,
          ele[1].length && (
            <ul key={index}>
              {ele[1].map((elee: any, index: number) => (
                <li key={index}>
                  <Link
                    href={headerLink(
                      elee.name,
                      elee?.linkParams?.params,
                      `categories.lvl3:=[\`${props.parent} > ${props.subparent} > ${ele[0]} > ${elee.name}\`]`
                    )}
                    onClick={() => {
                      props.close(),
                        searchState.categorymobilelink(modifiedData, ele[3])
                    }}
                    className="flex justify-between w-full border-gray-300 border-b pl-16 pr-4 py-2 text-sm"
                    style={{ color: `${ele[5] ? `${ele[4]}` : '#6b7280'}` }}
                  >
                    {elee.name}
                  </Link>
                </li>
              ))}
            </ul>
          ),
        ])}
      </Accordion>
    </div>
  )
}
