// import { useState } from 'react'
// import style from './mobile-app-bar.module.scss'
// import { MenuState } from '~/stores/menu'
import { MobileMenu } from '~/components/generals/menu/mobilemenu'
import { Logger } from '~/util'
// import { Backdrop } from '~/components/generals/menu/mobilemenu/backdrop/backdrop'
// import styledata from './../../../../styles/home.module.scss'
export const MobileAppBar = (props: any) => {
  return (
    <div>
      {props.drawerOpen && (
        <>
          {' '}
          {props.backdrop}
          <MobileMenu
            setSelectedIconData={props.setSelectedIconData}
            selectedIconData={props.selectedIconData}
            data={props.data}
            show={props.drawerOpen}
            close={() => props.setDrawerOpen(false)}
          />
        </>
      )}
    </div>
  )
}
export default MobileAppBar
