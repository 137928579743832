import { FC, useEffect, useState } from 'react'
import { AuthState } from '~/stores/auth'
import { UseNotificationState } from '~/stores/notify'
import { Logger } from '~/util'
import { RegxUtil } from '~/util/regx'
import { Close } from '../../icons/close'
import { Model } from '../../model/model'
import { Form } from './form/form'
import { LoginFormVariant } from './form/form.interface'
import style from './login-register-form.module.scss'

export type LoginRegisterFormProps = {
  variant: LoginFormVariant
  withoutModel?: boolean
  lean?: boolean
  buttonText?: string
  success?: () => void
  error?: () => void
}

export const useLoginRegisterForm = (props: LoginRegisterFormProps) => {
  const [formType, setFormType] = useState(props.variant)
  const auth = AuthState.useContainer()
  const notify = UseNotificationState.useContainer()
  const [registeredWIth, setRegisteredWIth] = useState<
    '' | 'email' | 'mobileno'
  >('')
  const [form, setForm] = useState({
    username: '',
    userdata: '',
    email: '',
    mobileno: '',
    password: '',
    otp: '',
  })

  const destroy = () => {
    setFormType('LOGIN')
    setForm({
      username: '',
      userdata: '',
      email: '',
      mobileno: '',
      password: '',
      otp: '',
    })
  }
  useEffect(() => {
    if (!auth.showLoginForm) {
      destroy()
    }
    return () => {
      destroy()
    }
  }, [auth.showLoginForm])

  const [variant, setVariant] = useState(props.variant)

  const sendRegisterOTP = (phoneno: any, email: any) => {
    setOtpLoader(true)
    if (phoneno.test(form.userdata) || email.test(form.userdata)) {
      auth
        .register(form.userdata)
        .then((_res) => {
          setOtpLoader(false)
          setFormType('REGISTER_OTP')
          setTimeLeft(59)
        })
        .catch((err) => {
          Logger.error(err)
          setOtpLoader(false)
        })
    } else {
      errorNotify()
      setOtpLoader(false)
    }
  }

  const [timeLeft, setTimeLeft] = useState(0)

  const errorNotify = () => {
    if (/[a-zA-Z]/.test(form.userdata)) {
      return notify.appNotification('Please enter valid Email ID', 'ERROR')
    }
    return notify.appNotification('Please enter valid Mobile Number', 'ERROR')
  }
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  const email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const [accountActive, setAccountActive] = useState(true)

  const submitEvent = async (type: LoginFormVariant) => {
    const otpRegx = (otp) => String(otp).length == 6 && /^[\d]{6}$/.test(otp)

    switch (type) {
      case 'LOGIN':
        setOtpLoader(true)
        if (phoneno.test(form.userdata) || email.test(form.userdata)) {
          auth
            .login(form.userdata, form.password, accountActive ? '0' : '1')
            .then((_res) => {})
            .catch((err) => {
              Logger.error(err)
              if (typeof err.response != 'undefined') {
                if (typeof err.response.data != 'undefined') {
                  if (
                    typeof err.response.data.error.account_status != 'undefined'
                  ) {
                    if (!err.response.data.error.account_status) {
                      setAccountActive(false)
                    }
                  }
                }
              }
              Logger.error('login error', err.response)
              setOtpLoader(false)
            })
        } else {
          errorNotify()
          setOtpLoader(false)
        }
        return
      case 'REGISTER':
        sendRegisterOTP(phoneno, email)

        // setFormType("LOGIN")
        break
      case 'REGISTER_OTP':
        if (
          (phoneno.test(form.userdata) || email.test(form.userdata)) &&
          otpRegx(form.otp)
        ) {
          auth
            .verifyRegisterOtp(form.userdata, form.otp)
            .then((_res) => {
              if (/[a-zA-Z]/gi.test(form.userdata)) {
                setRegisteredWIth('email')
                setForm({ ...form, email: form.userdata })
              } else {
                setRegisteredWIth('mobileno')
                setForm({ ...form, mobileno: form.userdata })
              }
              setFormType('PROFILE')
            })
            .catch((err) => {
              Logger.error(err)
              setOtpLoader(false)
            })
        } else {
          errorNotify()
          setOtpLoader(false)
        }
        // setFormType("PROFILE")
        break
      case 'PROFILE':
        if (!RegxUtil.PasswordSize.test(form.password)) {
          notify.appNotification(
            'Password should be between 5 - 15 characters in length',
            'ERROR'
          )
          return
        }
        auth
          .updateUserProfile(
            form.username,
            form.email,
            form.mobileno,
            form.password,
            '',
            registeredWIth
          )
          .then((_res) => {
            setFormType('LOGIN')
            auth.setShowLoginForm(false)
          })
          .catch((err) => {
            Logger.error(err)
            setOtpLoader(false)
          })
        break
      case 'WITH_OTP':
        if (
          (phoneno.test(form.userdata) || email.test(form.userdata)) &&
          otpRegx(form.otp)
        ) {
          auth
            .validateOtp(form.otp, form.userdata)
            .then((_res) => {
              setFormType('LOGIN')
              auth.setShowLoginForm(false)
            })
            .catch((err) => {
              Logger.error(err)
              setOtpLoader(false)
            })
        } else {
          // errorNotify()
          notify.appNotification('Please enter OTP', 'ERROR')
          setOtpLoader(false)
        }
        break
      case 'FORGET':
        if (phoneno.test(form.userdata) || email.test(form.userdata)) {
          auth
            .forgotPassword(form.userdata)
            .then((_res) => {
              setTimeLeft(59)
              setFormType('UPDATE')
            })
            .catch((err) => {
              Logger.error(err)
              setOtpLoader(false)
            })
        } else {
          errorNotify()
          setOtpLoader(false)
        }
        return
      case 'UPDATE':
        if (phoneno.test(form.userdata) || email.test(form.userdata)) {
          setFormType('LOGIN')
          auth.setShowLoginForm(false)
        } else {
          errorNotify()
          setOtpLoader(false)
        }
        return
      case 'FORGOT_PWD_OTP':
        if (
          (phoneno.test(form.userdata) || email.test(form.userdata)) &&
          otpRegx(form.otp)
        ) {
          auth
            .validateOtp(form.otp, form.userdata)
            .then((_res) => {
              setForm({
                ...form,
                password: '',
              })
              setFormType('UPDATE_PWD')
            })
            .catch((err) => {
              Logger.error(err)
              setOtpLoader(false)
            })
        } else {
          errorNotify()
          setOtpLoader(false)
        }
        return
      case 'UPDATE_PWD':
        if (phoneno.test(form.userdata) || email.test(form.userdata)) {
          if (!RegxUtil.PasswordLength.test(form.password)) {
            notify.appNotification(
              'Password should be in between 5 - 15 characters in length',
              'ERROR'
            )
            return
          }
          auth
            .updatePassword(form.password)
            .then((_res) => {
              setFormType('LOGIN')
              auth.setShowLoginForm(false)
            })
            .catch((err) => {
              Logger.error(err)
              setOtpLoader(false)
            })
        } else {
          errorNotify()
          setOtpLoader(false)
        }
        return
      case 'LOGIN_WITH_OTP':
        setOtpLoader(true)
        if (phoneno.test(form.userdata) || email.test(form.userdata)) {
          auth
            .forgotPassword(form.userdata)
            .then((_res) => {
              setOtpLoader(false)
              setFormType('WITH_OTP')
            })
            .catch((err) => {
              Logger.error(err)
              setOtpLoader(false)
            })
        } else {
          errorNotify()
          setOtpLoader(false)
        }
        return
      case 'FORGOT_PWD':
        setOtpLoader(true)
        if (phoneno.test(form.userdata) || email.test(form.userdata)) {
          requestOTP('FORGOT')
          //setOtpLoader(false)
        } else {
          errorNotify()
          setOtpLoader(false)
        }
        return
      default:
        auth.login(form.username, form.password).catch((err) => {
          Logger.error(err)
          setOtpLoader(false)
        })
    }
  }

  const [otpLoader, setOtpLoader] = useState(false)

  const requestOTP = async (val: 'LOGIN' | 'FORGOT' | 'REGISTER_OTP') => {
    //setOtpLoader(true)
    try {
      if (val == 'REGISTER_OTP') {
        sendRegisterOTP(phoneno, email)
      } else {
        const _res = await auth.forgotPassword(form.userdata)
        notify.appNotification(
          _res.data.data.message + ' ' + form.userdata,
          'SUCCESS'
        )
      }
      setForm({
        ...form,
        otp: '',
      })
      if (val == 'FORGOT') {
        setFormType('FORGOT_PWD_OTP')
        setOtpLoader(false)
        setTimeLeft(59)
      }
      if (val == 'LOGIN') {
        setFormType('WITH_OTP')
        setOtpLoader(false)
        setTimeLeft(59)
      }
      let date = new Date()
      let localDate = date.setMinutes(date.getMinutes() + 1)
      localStorage.setItem('otpExp', localDate.toString())
    } catch (err) {
      Logger.error(err)
      setOtpLoader(false)
    }
  }

  const updateField = (key: any, value: number) => {
    setForm({ ...form, [key]: value })
  }

  return {
    submitEvent,
    otpLoader,
    form,
    setForm,
    updateField,
    variant,
    setVariant,
    formType,
    setFormType,
    requestOTP,
    setOtpLoader,
    registeredWIth,
    timeLeft,
    accountActive,
  }
}

export const LoginRegisterForm: FC<LoginRegisterFormProps> = (props) => {
  const authState = AuthState.useContainer()
  const {
    form,
    submitEvent,
    updateField,
    registeredWIth,
    formType,
    requestOTP,
    setFormType,
    otpLoader,
    timeLeft,
    accountActive,
  } = useLoginRegisterForm(props)

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 991) {
      setIsMobile(true)
    }
    if (props.lean) {
      setIsMobile(true)
    }
    return () => {}
  }, [formType])

  const render = () => (
    <div
      className={` grid-cols-1 gap-1 ${
        !isMobile && 'sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2'
      } ${style.formWrapper}`}
    >
      {!isMobile && (
        <div className={` relative ${style.login_register_img}`}>
          <img
            src="https://img.poorvika.com//login-img.jpeg"
            alt="login-img"
            className="w-full hidden sm:block md:block lg:block xl:block 2xl:block h-full"
          />
        </div>
      )}
      <div className="...">
        <div className={style.login_register_form}>
          <button
            className={`${style.closeIcon}`}
            onClick={() => authState.setShowLoginForm(false)}
            name="close"
            title="close"
          >
            {props.withoutModel ? <></> : <Close />}
          </button>
          <Form
            timeLeft={timeLeft}
            updateField={(key, val) => updateField(key, val)}
            variant={formType}
            email={form.email}
            username={form.username}
            mobileno={form.mobileno}
            password={form.password}
            userdata={form.userdata}
            otp={form.otp}
            otpLoader={otpLoader}
            onSubmit={() => submitEvent(formType)}
            onRequestOTP={requestOTP}
            registeredWIth={registeredWIth}
            onChangeVariant={(val) => setFormType(val)}
            buttonText={`${props.buttonText}`}
            accountActive={accountActive}
          />
        </div>
      </div>
    </div>
  )

  return props.withoutModel ? (
    render()
  ) : (
    <Model
      modelClassName={style.login_model_class}
      model={authState.showLoginForm}
      index={11}
      closeModel={() => authState.setShowLoginForm(false)}
    >
      {' '}
      {render()}
    </Model>
  )
}
