import { FC, useState } from 'react'
import Link from 'next/link'
import style from './desktopmenu.module.scss'
import { SubChildTitle } from './subchildmenu-title'
import { MenuState } from '~/stores/menu'
/**
 * this is our custom desktop menu component
 * @returns {JSX.Element} this will return a desktop menu element
 */
export const DesktopMenu: FC = () => {
  const menuState = MenuState.useContainer()
  // const headerLink = (name: string, id: any) =>
  //   `/search?categories=categories:=[\`${name}\`]`
  const [showDropDown, setShowDropDown] = useState(false)
  const renderMenu = () => {
    menuState.getFullData()
    setTimeout(() => {
      setShowDropDown(true)
    }, 700)
  }
  const linkQuery = (path) => {
    if (!!path?.pageCode && !!path?.pageType) {
      return {
        pathname: `/[slug]/page`,
        query: {
          type: path?.pageType.toLowerCase(),
          slug: path?.pageCode,
        },
      }
    } else {
      return '/'
    }
  }
  const headerLink = (name, data) => {
    let objData: any = data?.find((e) => e.isDefault == true)
    if (objData?.isClassic) {
      switch (objData?.linkType.toUpperCase()) {
        case 'EXTERNALLINK':
          return objData?.externalUrl
        case 'PAGE':
          switch (objData?.pageType?.toUpperCase()) {
            case 'GENERAL':
              return linkQuery(objData)
            case 'CATEGORY':
              return linkQuery(objData)
            case 'BRAND':
              return linkQuery(objData)
            case 'OFFER':
              return linkQuery(objData)
          }
        default:
          return '/'
      }
    } else {
      return {
        pathname: `/s`,
        query: {
          categories: `categories:=[\`${name}\`]`,
          // stock_status: `stock_status:=[\`In Stock\`]`,
        },
      }
    }
  }
  return (
    <div className={`${style.menu_container}`}>
      <div className={style.desktopmenu}>
        {menuState.menuData.map((res: any, i: any) => (
          <div
            className={style.desktopmenu__menu_section}
            key={i}
            //onClick={() => setShowDropDown(false)}
            onMouseEnter={() => {
              renderMenu()
            }}
            //onMouseLeave={() => setShowDropDown(false)}
          >
            <Link
              href={headerLink(res.name, res?.linkParams?.params)}
              className={style.subChildTitle}
              style={{
                color: res.isHighlightable ? `${res.colorCode}` : '#000',
              }}
              onClick={() => setShowDropDown(false)}
            >
              {res.name}
            </Link>
            {res.children && showDropDown && (
              <div
                className={` ${
                  showDropDown ? style.dropdown : style.close_drop_down
                }`}
              >
                <div className={style.content}>
                  <SubChildTitle
                    data={res}
                    setShowDropDown={setShowDropDown}
                    showDropDown={showDropDown}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
