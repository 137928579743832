import axios from 'axios'
import { Config } from '~/config/config'
import { HttpClient } from './htttp'

export class AddressService {
  public static async getManyAddress() {
    return HttpClient.get('/crm/address', {})
  }

  public static async addAddress(data: any) {
    return HttpClient.post('/crm/address', data, {})
  }

  public static async getOneAddress(id: string) {
    return HttpClient.get(`/crm/address/${id}`, {})
  }

  public static async updateAddress(id: string, data: any) {
    return HttpClient.put(`/crm/address/${id}`, data, {})
  }

  public static async getGstDetail(_id: any) {
    return HttpClient.get('')
  }

  public static async delete(id: number) {
    return HttpClient.delete(`/crm/address/${id}`, {})
  }

  public static async getAddressDetailWithPinCode(pinCode: any) {
    return HttpClient.get(`/crm/address/postalcode/${pinCode}`, {})
  }
}
