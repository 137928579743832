import Link from 'next/link'
import style from '../app-bar.module.scss'
import { mrpPrice, sellingPrice } from '~/util/price'
import { PriceFormat } from '~/util'

/**
 * app bar contains basic cart
 * @returns {JSX.Element} returns app bar contains basic cart
 */
export const AppBarCart = (props) => {
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <tr>
      <td className="text-left text-sm py-1">
        <Link
          href={`/${props.data.slug}/p`}
          className={`${style.overflow_name}`}
        >
          {props.data.name}
        </Link>
      </td>
      <td className="text-sm py-1">
        {PriceFormat(
          props.data.pre_order
            ? props?.data?.preorderType == 'PERCENT_SALES_PRICE'
              ? sellingprice?.length != 0
                ? Math.round(
                    (sellingprice[0]?.price / 100) *
                      props?.data?.preorder_amount
                  )
                : Math.round(
                    (mrpprice[0]?.price / 100) * props?.data?.preorder_amount
                  )
              : props?.data?.preorder_amount
            : sellingprice?.length != 0
            ? sellingprice[0]?.price
            : mrpprice[0]?.price
        )}
      </td>
    </tr>
  )
}
