import style from '../app-bar.module.scss'
import { AuthState } from '~/stores/auth'
import { useEffect, useLayoutEffect, useState } from 'react'
import ShareLocation from '../../Sharelocation/Sharelocation'
import { StoreService } from '~/services/stores.service'
import { LocationMarker } from '../../icons/location-marker'
import { PimHomeService } from '~/services/pim.service'
import Cookies from 'js-cookie'
import { Logger } from '~/util'
import { UseNotificationState } from '~/stores/notify'
import { useRouter } from 'next/router'

/**
 * app bar contains basic location
 * @returns {JSX.Element} returns app bar contains location
 */
export const AppBarLocation = () => {
  const authState = AuthState.useContainer()
  const notify = UseNotificationState.useContainer()
  const [LocationShare, setLocationShare] = useState(false)
  const [, setLoading] = useState(true)
  const [locationdata, setLocationdata] = useState('')
  const [validPincode, setValidPincode] = useState('')
  const router = useRouter()
  useEffect(() => {
    let user = Cookies.getJSON()['user']

    if (!!!user) {
      let localData = localStorage.getItem('myData')

      if (localData != 'null') {
        authState.updatePinCode(localData)
      }
    } else {
      let logdata = localStorage.getItem('myData')
      if (!!!logdata) {
        authState.updatePinCode(
          String(authState.state.pincode == '' ? '' : authState.state.pincode)
        )
        localStorage.setItem(
          'myData',
          authState.state.pincode == '' ? '' : authState.state.pincode
        )
      } else {
        authState.updatePinCode(String(logdata))
      }
    }
  }, [authState.state])

  useEffect(() => {
    if (authState.pinCode?.length == 6 && authState.pinCode != 'undefined') {
      StoreService.sharelocation(authState.pinCode)
        .then((res) => {
          {
            if (res?.data.data) {
              setLocationdata(res?.data.data[0].district_name)
              localStorage.setItem('myData', authState.pinCode)
              setLoading(false)
              setLocationShare(false)
            } else {
              notify.appNotification('Please Enter Valid Pincode', 'ERROR')
              authState.clearPinCode('')
              setLocationdata('')
              setLoading(false)
            }
          }
        })
        .catch((err) => {
          Logger.error(err)
          //notify.appNotification('Please Enter Valid Pincode', 'ERROR')
          //authState.clearPinCode('')
          //setLocationdata('')
          setLoading(false)
        })
    }
  }, [authState.updatePinCode])

  return (
    <div
      className={`${
        locationdata && style.app_bar__locate_pad
      } lg:float-right md:float-right`}
    >
      <div onClick={() => setLocationShare(true)}>
        <div className={`${style.caption}`}>Delivery to</div>
        <b className={`flex ${style.name}`}>
          <button
            className={`capitalize focus:outline-none font-semibold ${
              locationdata &&
              locationdata.length > 9 &&
              style.truncate_head_text
            }`}
          >
            {locationdata == '' ? 'Location ' : locationdata}
          </button>
          <LocationMarker
            fill="#fff"
            className={`${
              locationdata ? style.resize_map : style.location_map
            }`}
          />
        </b>
        {locationdata != null && (
          <p className={`${style.truncate_text}`}>{locationdata}</p>
        )}
      </div>
      {LocationShare ? (
        <ShareLocation
          setValidPincode={setValidPincode}
          validPincode={validPincode}
          LocationShare={LocationShare}
          setLocationShare={setLocationShare}
        />
      ) : (
        ''
      )}
    </div>
  )
}
