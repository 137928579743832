type SpinnerProps = {
  size?: number
  thick?: number
}

export const Spinner = (props: SpinnerProps) => {
  const wh = props.size ? props.size * 10 : 50
  const thick = props.thick ? props.thick : 5
  return (
    <svg className="spinner" width={wh} height={wh} viewBox={`0 0 ${wh} ${wh}`}>
      <circle
        className="path"
        cx={wh / 2}
        cy={wh / 2}
        r={wh / 2 - thick}
        fill="none"
        strokeWidth={thick}
      />
    </svg>
  )
}
export default Spinner
