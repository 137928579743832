import { useRouter } from 'next/router'
import style from './account-dropdown.module.scss'
import { AuthState } from '~/stores/auth'
import Img from '../../img/img'
/**
 * this is the selected AccountDropDown
 */
export const AccountDropDown = () => {
  const router = useRouter()
  const authState = AuthState.useContainer()

  const headLink = [
    {
      text: 'My Orders',
      link: '/orders?type=history',
      img: 'https://img.poorvika.com//order.png',
    },
    {
      text: 'Wishlist & Saved',
      link: '/account?type=wish-list',
      img: 'https://img.poorvika.com//save.png',
    },
    // {
    //   text: 'My Reviews',
    //   link: '/reviews?type=my-reviews',
    //   img: 'https://img.poorvika.com//review_icon.png',
    // },
    {
      text: 'Saved Address',
      link: '/account?type=address',
      img: 'https://img.poorvika.com//address.png',
    },
  ]
  const headLinkSecond = [
    {
      text: 'Contact Us',
      link: '/contact-us',
      img: 'https://img.poorvika.com//contact.png',
    },
    {
      text: 'Account Settings',
      link: '/account?type=profile',
      img: 'https://img.poorvika.com//Accoun-setting.png',
    },
  ]
  const navigate = (pa: string) => router.push(pa)
  return (
    <div className={style.account_dropdown}>
      <div>
        <div className={style.account_dropdown__head}>
          <span>Account</span>
        </div>
        <div>
          <div className={style.list}>
            {headLink.map((el: any, i: number) => (
              <div
                key={i}
                className={style.list_item}
                onClick={() => navigate(el.link)}
              >
                <span className={style.list_item__image}>
                  <img
                    src={el.img}
                    alt={el.txt}
                    // loader={() => el.img}
                    // blurDataURL={el.img}
                    width={15}
                    height={15}
                    placeholder="blur"
                  />
                </span>
                <span className="text-sm">{el.text}</span>
              </div>
            ))}
          </div>
          <div className={style.lists}>
            {headLinkSecond.map((el: any, i: number) => (
              <div
                key={i}
                className={style.list_item}
                onClick={() => navigate(el.link)}
              >
                <span className={style.list_item__image}>
                  <img
                    src={el.img}
                    alt={el.txt}
                    // loader={() => el.img}
                    // blurDataURL={el.img}
                    width={15}
                    height={15}
                    placeholder="blur"
                  />
                </span>
                <span className="text-sm">{el.text}</span>
              </div>
            ))}
            <div className={style.list_item} onClick={() => authState.logout()}>
              <span className={style.list_item__image}>
                <img
                  src={'https://img.poorvika.com//login.png'}
                  alt="logout"
                  // loader={() => 'https://img.poorvika.com//login.png'}
                  // blurDataURL={'https://img.poorvika.com//login.png'}
                  width={15}
                  height={15}
                  placeholder="blur"
                />
              </span>
              <span className="text-sm">Logout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
