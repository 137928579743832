import { FC } from 'react'
import style from './overlay.module.scss'

type OverlayProps = {
  type: 'OVERLAY' | 'CONTAINER' | 'MENU_OVERLAY'
  children: any
  onClose?: () => void
}

export const Overlay: FC<OverlayProps> = (props) => {
  const mapClass = {
    OVERLAY: style.spinner__overlay,
    CONTAINER: style.spinner__container,
    MENU_OVERLAY: style.menu_overlay,
  }
  return (
    <div className={`${mapClass[props.type]}`}>
      <div className={style.children}>{props.children}</div>
      <span
        onClick={() => {
          if (props.onClose) {
            props.onClose()
          }
        }}
        className={style.menu_overlay__close}
      />
    </div>
  )
}
export default Overlay
