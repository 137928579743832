import { FC } from 'react'

export type CloseProps = {
  iconColor?: string
  strokeWidth?: number
  className?: string
}
export const Close: FC<CloseProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={props.className ? props.className : 'h-6 w-6'}
    fill="none"
    viewBox="0 0 24 24"
    stroke={props.iconColor ? props.iconColor : 'currentColor'}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth ? props.strokeWidth : 2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
)
