import { FC } from 'react'
import { AuthState } from '~/stores/auth'
import { FavState } from '~/stores/favourite'
import { Logger } from '~/util'
import { Heart } from '../icons/heart'

export type FavouriteButtonProps = {
  id?: any
  item_code: any
  label?: string
  labelStyle?: any
  done?: any
  slug: any
  pimStyle?: any
}

export const FavouriteButton: FC<FavouriteButtonProps> = (props) => {
  const authState = AuthState.useContainer()
  const favState = FavState.useContainer()
  const favUnFave = async () => {
    if (!authState.isLoggedIn()) {
      authState.setShowLoginForm(true)
    } else if (!favState.itemInWishList(props.slug)) {
      favState.addWhislist({
        slug: props.slug,
        itemcode: props.item_code,
      })
      if (!!props.done) {
        props.done('add')
      }
    } else {
      // remove from fav
      favState.delWhislist(props.slug)
      if (!!props.done) {
        props.done('delete')
      }
    }
  }
  return (
    <p>
      {!!authState.state.id && favState.itemInWishList(props.slug) ? (
        <span
          onClick={() => favUnFave()}
          className="flex items-center justify-center cursor-pointer"
        >
          <Heart type="FILLED" pimStyle={props.pimStyle} />
          {props.label ? (
            <span className={props.labelStyle}>{props.label}</span>
          ) : (
            ''
          )}
        </span>
      ) : (
        <span
          onClick={() => favUnFave()}
          className="flex items-center justify-center cursor-pointer"
        >
          <Heart type="STROKE" pimStyle={props.pimStyle} />
          {props.label ? (
            <span className={props.labelStyle}>{props.label}</span>
          ) : (
            ''
          )}
        </span>
      )}
    </p>
  )
}
