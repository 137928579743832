import axios from 'axios'
import { Config } from '~/config/config'
import { HttpClient } from './htttp'
import { CHANNEl } from './product.service'

export class CartService {
  public static async getItems() {
    return HttpClient.get('/crm/cart', {
      headers: {
        channel: 'ONLINE',
      },
    })
  }

  public static async addItem(data: any) {
    return HttpClient.post('/crm/cart', data, {})
  }

  public static async updateQuantity(
    id: any,
    body: {
      quantity: number
      item_code: string
      pincode: string
    }
  ) {
    if (body.quantity) {
      return HttpClient.put(`/crm/cart/${id}`, body, {})
    }
    return this.delete(id)
  }

  public static async delete(id: any) {
    return HttpClient.delete(`/crm/cart/${id}`, {})
  }

  // /**
  //  * this is yet to get complete
  //  * @returns {Promise<AxiosResponse<any>>} response
  //  */
  // public static async couponCode(): Promise<AxiosResponse<any>> {
  //   return HttpClient.post('/cart')
  // }

  public static async getPaymentMethods(data: {
    products: {
      product_id: number
      item_code: string
    }[]
  }) {
    return HttpClient.post('/crm/getPaymentMethods', data, {
      headers: {
        channel: 'ONLINE',
      },
    })
  }

  public static async validateCoupon(data: {
    customerId: string
    couponCode: string
    channel: string
    cartValue: number
    customerMobile: string
    customerEmail: string

    items: {
      itemId: string
      itemSellingPrice: number
      quantity: number
    }[]
  }) {
    return HttpClient.post('/offers/coupons/validateCoupon', data, {
      headers: {
        'x-api-key': Config.OFFERS_KEY,
        channel: CHANNEl.ONLINE,
      },
    })
  }

  public static async updateAdld(id: string, data: any) {
    return HttpClient.put(`/crm/adld?slug=${id}`, data, {})
  }

  public static async couponsForCheckout(data: any) {
    return HttpClient.post('/offers/coupons/couponsForCheckout', data, {
      headers: {
        'x-api-key': Config.OFFERS_KEY,
        channel: CHANNEl.ONLINE,
      },
    })
  }
}
