import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { ProductService } from '~/services/product.service'
import { Logger } from '~/util'
import { AuthState } from './auth'

const RecentlyViewed = () => {
  const auth = AuthState.useContainer()
  const [items, setItems] = useState([])
  // useEffect(() => {
  //   onInit()
  // }, [auth.state.id])

  const onInit = () => {
    if (auth.state.id) {
      sync()
    }
    return init()
  }

  const init = async () => {
    if (auth.state.id != '') {
      await ProductService.getRecentlyViewedProducts()
        .then((res) => {
          setItems(res.data.data)
          return res
        })
        .catch(async (err) => {
          Logger.error(err)
        })
    } else {
      const recent = localStorage.getItem('recent')
      if (recent) {
        const recentVal = JSON.parse(recent)
        return await getNonLoginData(recentVal.recent)
      }
    }
  }

  const getNonLoginData = async (data: any) => {
    let da: any = []
    data.forEach((el: any) => {
      if (!da.find((e: any) => e.item_code == el.item_code)) {
        da.push(el)
      }
    })
    return ProductService.getRecentViewedAnonymous(da)
      .then((res) => {
        if (!!res) {
          if (!!res.data.data) {
            setItems(res.data.data.filter((e: any) => !!e.name))
          }
        }
        return
      })
      .catch(Logger.error)
  }

  const sync = async () => {
    const recent = localStorage.getItem('recent')
    if (recent) {
      let r = JSON.parse(recent)
      if (!!r.recent) {
        const recentVal = r.recent.filter((e) => !!e.slug)
        return await ProductService.addRecentlyViewProducts({
          search_list: recentVal,
        })
          .then((_res) => {
            localStorage.removeItem('recent')
          })
          .catch(Logger.error)
      }
    }
  }

  const addItem = async (slug: any, item_code: any) => {
    if (auth.state.id != '') {
      return await ProductService.addRecentlyViewProducts({
        search_list: [{ slug, item_code }],
      })
        .then((_res) => {})
        .catch((err) => {
          Logger.error(err)
          let recent = localStorage.getItem('recent')
          if (!!recent) {
            let recentVal = JSON.parse(recent)
            const recentItem = recentVal.recent.filter(
              (ele: any) =>
                !(ele.product_id == slug && ele.item_code == item_code)
            )
            recentVal.recent = [{ slug, item_code }, ...recentItem].filter(
              (e) => !!e.slug
            )
            localStorage.setItem('recent', JSON.stringify(recentVal))
          } else {
            localStorage.setItem(
              'recent',
              JSON.stringify({ recent: [{ slug, item_code }] })
            )
          }
        })
    } else {
      let recent = localStorage.getItem('recent')
      if (!!recent) {
        let recentVal = JSON.parse(recent)
        const recentItem = recentVal.recent.filter(
          (ele: any) => !(ele.product_id == slug && ele.item_code == item_code)
        )
        recentVal.recent = [{ slug, item_code }, ...recentItem].filter(
          (e) => !!e.slug
        )
        localStorage.setItem('recent', JSON.stringify(recentVal))
      } else {
        localStorage.setItem(
          'recent',
          JSON.stringify({ recent: [{ slug, item_code }] })
        )
      }
    }
  }

  return {
    addItem,
    items,
    onInit,
    sync,
  }
}

export const RecentlyViewedState: any = createContainer(RecentlyViewed)
