import { useState, useEffect } from 'react'
import { AccordionProps } from './accordion.interface'

export const useAccordion = (props: AccordionProps) => {
  const [selected, setSelected] = useState([0] as any)

  useEffect(() => {
    if (typeof props.selected == 'object') {
      setSelected([...props.selected])
    } else {
      setSelected([props.selected])
    }
  }, [props.selected])

  const updateSelected = (i: number, variant: string) => {
    if (variant == 'MULTIPLE') {
      if (selected.includes(i)) {
        setSelected(selected.filter((ele: any) => ele !== i))
      } else {
        setSelected([...new Set([...selected, i])])
      }
      return
    }
    if (selected.includes(i)) {
      setSelected([])
    } else {
      setSelected([i])
    }
  }

  return {
    selected,
    updateSelected,
  }
}
