import { useState } from 'react'
import { createContainer } from 'unstated-next'

const Price = () => {
  var sortedProducts1 = []
  const [sellingPriceList, setSellingPriceList] = useState([])
  const [mrpPriceList, setMrpPriceList] = useState([])
  const sellingprice = (datas: any) => {
    let arr = []
    const currentdate = new Date()
    datas?.length >= 1 &&
      datas?.forEach((element: any) => {
        const startDate = new Date(element.startDate)
        let endDate: any =
          element.endDate == null ? element.endDate : new Date(element.endDate)
        if (currentdate.valueOf() >= startDate.valueOf()) {
          if (currentdate.valueOf() <= endDate?.valueOf() || endDate == null) {
            arr.push(element)
          }
        }
      })
    sortedProducts1 = arr.sort(
      (objA, objB) => Number(objB.startDate) - Number(objA.startDate)
    )
    setSellingPriceList(sortedProducts1)
  }
  const mrpprice = (datas: any) => {
    let arr = []
    const currentdate = new Date()
    datas?.length >= 1 &&
      datas?.forEach((element: any) => {
        const startDate = new Date(element.startDate)
        if (currentdate.valueOf() >= startDate.valueOf()) {
          arr.push(element)
        }
      })
    let sortedProducts = arr.sort(
      (objA, objB) => Number(objB.startDate) - Number(objA.startDate)
    )
    setMrpPriceList(sortedProducts)
  }

  return {
    sellingprice,
    setSellingPriceList,
    sellingPriceList,
    setMrpPriceList,
    mrpPriceList,
    mrpprice,
    sortedProducts1,
  }
}

export const PriceState: any = createContainer(Price)
