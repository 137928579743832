import { Config } from '~/config/config'
import { HttpClient } from './htttp'

export class AuthService {
  public static async loginRegular(data: {
    username: string
    password: string
    loginstatus: string
  }) {
    return HttpClient.post(
      '/crm/auth/login',
      {
        userdata: data.username,
        password: data.password,
        loginstatus: data.loginstatus,
      },
      {}
    )
  }

  public static async register(data: { userdata: string }) {
    return HttpClient.post('/crm/auth/register', data, {})
  }

  public static async addProfileDetail(data: {
    username: string
    email: string
    mobileno: string
    password: string
  }) {
    return HttpClient.post(
      '/crm/account/profile',
      {
        name: data.username,
        email: data.email,
        mobileno: data.mobileno,
        password: data.password,
      },
      {}
    )
  }

  public static async registerOtp(data: { userdata: string; otp: string }) {
    return HttpClient.post('/crm/auth/register', data, {})
  }

  public static async forgotPassword(data: { userdata: string }) {
    return HttpClient.put('/crm/auth/getOtp', {
      username: data.userdata,
    })
  }

  public static async getOTPForLogin(data: { userdata: string }) {
    return HttpClient.post('/crm/auth/login/otp', data, {})
  }

  public static async deactivateAccount(data: { password: string }) {
    return HttpClient.post('/crm/account/deactivate', data, {})
  }

  public static async updatePassword(data: { password: string }) {
    return HttpClient.put('/crm/auth/updatePassword', data, {})
  }

  public static async changePassword(data: { newpassword: string }) {
    return HttpClient.post('/crm/changePassword', data)
  }

  public static async logout() {
    return HttpClient.delete('/crm/auth/logout')
  }

  public static async getToken() {
    return HttpClient.get('/crm/auth/token')
  }

  public static async generateForgotPasswordOtp(name: string) {
    return HttpClient.put('/crm/auth/getOtp', { username: name })
  }

  public static async validateOtp(data: { otp: string; userdata: string }) {
    return HttpClient.post('/crm/auth/login/otp', data, {})
  }

  public static async requestChangePasswordOTP() {
    return HttpClient.put('/crm/updatepassword', { password: '' }, {})
  }

  public static async updatePasswordWithOTP(data: {
    password: string
    otp: string
  }) {
    return HttpClient.put('/crm/updatepassword', data, {})
  }
}
