import { FC } from 'react'

type CheveronRightProps = {
  className?: any
  iconcolor?: string
  slidarrow?: any
}
export const CheveronRight: FC<CheveronRightProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={props.className ? props.className : 'h-6 w-6'}
    fill="none"
    viewBox="0 0 24 24"
    // stroke={props.iconcolor ? props.iconcolor : 'currentColor'}
    stroke={
      props.slidarrow
        ? 'currentColor'
        : props.iconcolor
        ? props.iconcolor
        : 'currentColor'
    }
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5l7 7-7 7"
    />
  </svg>
)
