import { useState } from 'react'
import { createContainer } from 'unstated-next'

export const ShowcaseCarousel = () => {
  const [modal, setModal] = useState(false)
  const [images, setImages] = useState([] as any)
  return {
    modal,
    setModal,
    images,
    setImages,
  }
}

export const ShowcaseCarouselState: any = createContainer(ShowcaseCarousel)
