import { useCallback, useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { UserService } from '~/services/user.service'
import { Logger } from '~/util'
import { AuthState } from './auth'
import { UseNotificationState } from './notify'
const Favourite = () => {
  const [favouriteItem, setFavouriteItem] = useState([])
  const authState = AuthState.useContainer()
  const notify = UseNotificationState.useContainer()

  useEffect(() => {
    if (authState.state.id) {
      initialData()
    }
  }, [authState.state.id])

  const initialData = async () => {
    try {
      const { data } = await UserService.getWishList()

      if (data.data) {
        setFavouriteItem(data.data)
      } else {
        setFavouriteItem([])
      }
      return
    } catch (err) {}
  }
  const addWhislist = async (data: any) => {
    await UserService.addToWishList(data)
      .then((_res) => {
        initialData()
        if (!!_res.data.data) {
          notify.appNotification(_res.data.data.message, 'SUCCESS')
        } else if (!!_res.data.error) {
          notify.appNotification(_res.data.error.message, 'ERROR')
        }
      })
      .catch(Logger.error)
  }
  const delWhislist = async (item_code: number, noNotificaation?: boolean) => {
    return await UserService.deleteWish(item_code)
      .then((_res) => {
        if (!noNotificaation) {
          notify.appNotification(_res.data.data.message, 'ERROR')
        }
        initialData()
      })
      .catch(Logger.error)
  }

  const itemInWishList = useCallback(
    (id) => favouriteItem.map((item: any) => item.slug).includes(id),
    [favouriteItem]
  )
  return {
    initialData,
    favouriteItem,
    addWhislist,
    delWhislist,
    itemInWishList,
  }
}

export const FavState: any = createContainer(Favourite)
