import { Close } from '~/components/ui/icons/close'
import Img from '~/components/ui/img/img'
import { Model } from '~/components/ui/model/model'
import { ShowcaseCarouselState } from '~/stores/showcase-carousel'
import style from './expanded-showcasecarousel.module.scss'
import { useLayoutEffect, useState } from 'react'
export const ExpandedShowCaseCarousel = (props) => {
  const showcaseCarouselState = ShowcaseCarouselState.useContainer()
  const renderVideoIframe = (i) => {
    const variantClassMap: any = {
      Youtube: 'https://www.youtube.com/embed/',
      Vimeo: 'https://player.vimeo.com/video/',
      Dailymotion: 'https://www.dailymotion.com/embed/video/',
    }
    return variantClassMap[i.media.playerType] + i.media.videoID
  }
  const [modedata, setModeData] = useState('' as any)

  useLayoutEffect(() => {
    if (window.innerWidth && window.innerWidth <= 768) {
      let mode = 'mobileweb'
      setModeData(mode)
    } else {
      let mode = 'web'
      setModeData(mode)
    }
  }, [])

  return (
    <div>
      <Model
        className={style.popup_carousel_child}
        modelClassName={style.model_popup_name}
        model={showcaseCarouselState.modal}
        closeModel={() => showcaseCarouselState.setModal(false)}
        index={1000}
      >
        <button
          onClick={() => {
            showcaseCarouselState.setModal(false)
            showcaseCarouselState.setImages([])
          }}
          className={style.close}
          style={{
            zIndex: 1001,
          }}
        >
          <Close />
        </button>
        <div
          className={style.imageShowcaseOverlay}
          style={{
            gridTemplateColumns: `repeat(${showcaseCarouselState.images.length}, 100vw)`,
          }}
        >
          {showcaseCarouselState.images.map((el: any, i: number) => (
            <div className={style.imageShowcaseOverlay__img} key={i}>
              {el.type == 'image' ? (
                // <img src={el.media?.url} alt="" placeholder="blur" />
                <Img
                  rWidth={{
                    mobileweb: 500,
                    web: 1600,
                  }}
                  rHeight={{
                    mobileweb: 500,
                    web: 1600,
                  }}
                  deviceType={modedata}
                  src={el.media?.url}
                  loader={() => el.media?.url}
                  blurDataURL={el.media?.url}
                  placeholder="blur"
                  alt="media"
                />
              ) : (
                <iframe
                  width="90%"
                  height="300px"
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 40,
                  }}
                  src={`${renderVideoIframe(el)}`}
                  title="video player"
                  allow="fullscreen; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              )}
              <span>
                {i + 1}/{`${showcaseCarouselState.images?.length}`}
              </span>
            </div>
          ))}
        </div>
      </Model>
    </div>
  )
}
