import Typesense from 'typesense'
import { Config } from '~/config/config'

export const TypesenseClient = (query: any) => {
  let client = new Typesense.Client({
    nodes: [
      {
        host: Config.TIME_SENSE_BASE_URL,
        port: '443',
        protocol: 'https',
      },
    ],
    apiKey: Config.TIME_SENSE_API_KEY,
    connectionTimeoutSeconds: 2,
  })
  return client.multiSearch.perform(query)
}
