import { Lang } from '~/translations/LangKeys'

export const LoginRegisterFormText: any = {
  SignIn: {
    [Lang.ENGLISH]: 'Sign In',
    [Lang.TAMIL]: 'உள்நுழைக',
  },
  SignUP: {
    [Lang.ENGLISH]: 'Sign Up',
    [Lang.TAMIL]: 'பதிவு',
  },
  RequestOTP: {
    [Lang.ENGLISH]: 'Request OTP',
    [Lang.TAMIL]: 'Otp ஐக் கோருங்கள்',
  },
  Login: {
    [Lang.ENGLISH]: 'Login',
    [Lang.TAMIL]: 'உள்நுழைய',
  },
  Register: {
    [Lang.ENGLISH]: 'Register',
    [Lang.TAMIL]: 'பதிவு',
  },
  OTP: {
    [Lang.ENGLISH]: 'Submit',
    [Lang.TAMIL]: 'Register',
  },
  ForgetPassword: {
    [Lang.ENGLISH]: 'Forget Password',
    [Lang.TAMIL]: 'கடவுச்சொல்லை மறந்து',
  },
  EnterOtp: {
    [Lang.ENGLISH]: 'EnterOTP',
    [Lang.TAMIL]: 'OTP ஐ உள்ளிடவும்',
  },
  UpdatePasswordWithOtp: {
    [Lang.ENGLISH]: 'Update Password',
    [Lang.TAMIL]: 'OTP ஐ உள்ளிடவும்',
  },
  Submit: {
    [Lang.ENGLISH]: 'Submit',
    [Lang.TAMIL]: 'சமர்ப்பிக்க',
  },
  LoginRegisterText: {
    [Lang.ENGLISH]: 'Upon login, I agree to Poorvika’s Privacy Policy & Terms',
    [Lang.TAMIL]:
      'உள்நுழைந்தவுடன், பூர்விகாவின் தனியுரிமைக் கொள்கை & விதிமுறைகளை நான் ஒப்புக்கொள்கிறேன்',
  },
  Otpvalues: {
    [Lang.ENGLISH]: 'Enter OTP to 9235648020',
    [Lang.TAMIL]: '9235648020 க்கு OTP ஐ உள்ளிடவும்',
  },
  TimeDuration: {
    [Lang.ENGLISH]: ' Retry in  00:30 ',
    [Lang.TAMIL]: '00:30 இல் மீண்டும் முயற்சிக்கவும்',
  },
  UserName: {
    [Lang.ENGLISH]: 'Name',
    [Lang.TAMIL]: 'பெயர்',
  },
  UserData: {
    [Lang.ENGLISH]: 'Email / Mobile Number',
    [Lang.TAMIL]: 'பயனர் மின்னஞ்சல்/தொலைபேசி எண்',
  },
  Email: {
    [Lang.ENGLISH]: 'Email',
    [Lang.TAMIL]: 'மின்னஞ்சல்',
  },
  MobileNo: {
    [Lang.ENGLISH]: 'Mobile No',
    [Lang.TAMIL]: 'அலைபேசி எண்',
  },
  Password: {
    [Lang.ENGLISH]: 'Password',
    [Lang.TAMIL]: 'கடவுச்சொல்',
  },
  ToAccessYourOrderEasily: {
    [Lang.ENGLISH]: 'To access your Order easily',
    [Lang.TAMIL]: 'உங்கள் ஆர்டரை எளிதாக அணுக',
  },
}
