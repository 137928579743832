import { FC } from 'react'
import { Notify } from '~/stores/notify'
import { CheckCircle } from '../icons/check-circle'
import { Close } from '../icons/close'
import { CloseFilledIcon } from '../icons/close-filled'
import { Exclamation } from '../icons/exclamation'
import style from './notification.module.scss'

export const NotifyEle: FC<Notify> = (props) => {
  const classMap = {
    ERROR: style['notifyWrapper__content--error'],
    SUCCESS: style['notifyWrapper__content--success'],
    WARNING: style['notifyWrapper__content--warning'],
  }
  const colorMap = {
    ERROR: '#ff4d4f',
    SUCCESS: '#52c41a',
    WARNING: '#faad14',
  }
  return (
    <div className={`${style.notifyWrapper__content} ${classMap[props.type]}`}>
      <div className="mr-2">
        <CloseFilledIcon iconColor={colorMap[props.type]}></CloseFilledIcon>
      </div>
      {/* <div className="capitalize flex-1">{props.message}</div> */}
      <div className="flex text-center ...">
        <div>{props.message}</div>
      </div>
      {props.cancalable ? (
        <div onClick={() => props.close()}>
          <Close iconColor="red" className="h-4 w-4 ml-2" strokeWidth={2} />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
