import { Lang } from '~/translations/LangKeys'

export const AppBarText: any = {
  SearchPlaceHolder: {
    [Lang.ENGLISH]: 'Search for Products, Brands, Offers',
    [Lang.TAMIL]: 'தயாரிப்புகள், பிராண்டுகள், சலுகைகளைத் தேடுங்கள்',
  },
  Stores: {
    [Lang.ENGLISH]: 'Stores',
    [Lang.TAMIL]: 'கடைகள்',
  },
  Locate: {
    [Lang.ENGLISH]: 'Locate',
    [Lang.TAMIL]: 'கண்டுபிடி',
  },
  Items: {
    [Lang.ENGLISH]: 'Items',
    [Lang.TAMIL]: 'பொருட்களை',
  },
  Account: {
    [Lang.ENGLISH]: 'Account',
    [Lang.TAMIL]: 'கணக்கு',
  },
}
