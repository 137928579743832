import { HttpClient } from './htttp'
import { Config } from '~/config/config'
import { Logger } from '~/util'

export class ReviewService {
  public static async addNewReview(data: {
    itemCode: string
    productId: number
    title: string
    text: string
    rating: number
    ratings: any
    images: any
    slug: any
    productCode: any
  }) {
    Logger.log(data, 'data')

    return HttpClient.post('/review/review', data, {})
  }

  public static async getOneReview(reviewId: any) {
    return HttpClient.get(`/review/review/${reviewId}`, {})
  }
  public static async updateReview(reviewId: any, body?: any) {
    return HttpClient.put(`/review/review/${reviewId}`, body, {})
  }
  public static async deleteReview(reviewId: any) {
    return HttpClient.delete(`/review/review/${reviewId}`, {})
  }

  public static async getRatings(
    limit?: number,
    page?: any,
    productCode?: any,
    sort?: string,
    filter?: any
  ) {
    return HttpClient.get(
      `/review/review?limit=${limit}&page=${page}&productCode=${productCode}&sort=${sort}&filter=${filter}`,
      {}
    )
  }
  public static async getReviewImage(
    // itemCode?: string,
    // productId?: any,
    props: {
      limit?: number
      page?: any
      sort?: any
      slug?: any
      productCode?: any
    }
  ) {
    return HttpClient.get(
      `/review/review-images?limit=${props.limit}&page=${props.page}&sort=${props.sort}&slug=${props.slug}&productCode=${props.productCode}`,
      {}
    )
  }
  public static async getMyReview(limit?: number, page?: any, sort?: any) {
    return HttpClient.get(
      `/review/review/myReview?limit=${limit}&page=${page}&sort=${sort}`,
      {}
    )
  }

  public static async getRecommends(productId?: any) {
    return HttpClient.get(`/review/review/product/${productId}`, {})
  }

  public static async getReviewfields(
    slug?: any,
    productCode?: any,
    itemCode?: any
  ) {
    return HttpClient.get(
      `/review/review/getReviewDetails?slug=${slug}&productCode=${productCode}&itemCode=${itemCode}`,
      {}
    )
  }
  public static async getFrequent(
    limit?: number,
    page?: any,
    productId?: any,
    sort?: string,
    filter?: any,
    frequently?: any
  ) {
    return HttpClient.get(
      `/review/review?limit=${limit}&page=${page}&productId=${productId}&sort=${sort}&filter=${filter}&frequently=${frequently}`,
      {}
    )
  }
}
