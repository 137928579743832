import { type } from 'os'
import { Config } from '~/config/config'
import { HttpClient } from './htttp'

export class UserService {
  public static async getWishList() {
    return HttpClient.get('/crm/wishlist/getfavitem', {
      headers: {
        channel: 'ONLINE',
      },
    })
  }

  public static async addToWishList(data: any) {
    return HttpClient.post(
      '/crm/wishlist/addfavitem',
      { slug: data.slug, itemcode: data.itemcode },
      {}
    )
  }

  public static async likeDislike(body: {
    type: 'like' | 'disLike'
    reset: boolean
    reviewId: string
  }) {
    return HttpClient.post(`/review/review-likes`, body, {})
  }
  public static async deleteWish(id: number) {
    return HttpClient.delete(`/crm/wishlist/rmvfavitem/${id}`, {})
  }

  public static async getAccount() {
    return HttpClient.get('/crm/account', {})
  }

  public static async updateAccountDetail(data: any) {
    return HttpClient.put('/crm/account', data, {})
      .then((res) => {
        return res
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  public static async updateProfileImage(data: any) {
    return HttpClient.post('/crm/imageupload', data, {})
      .then((res) => {
        return res
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  public static async updateReviewImage(data: any) {
    return HttpClient.post('/crm/imageupload/multipleImage', data, {})
      .then((res) => {
        return res
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  public static async saveProfileImage(data: any) {
    return HttpClient.post('/crm/imageupload/saveimage', { image: data }, {})
      .then((res) => {
        return res
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  public static async saveReviewImage(data: any) {
    return HttpClient.post('/imageupload/multipleImage', { image: data })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  public static async addReview(data: {
    item_code: string
    product_id: number
    title: string
    text: string
    rating: number
  }) {
    return HttpClient.post('/review', data)
  }

  public static async addReviewNew(data: {
    item_code: string
    product_id: number
    title: string
    text: string
    rating: number
    ratings: any[]
    images: any[]
    // recommended?: any
  }) {
    return HttpClient.post('/review', data)
  }
}
