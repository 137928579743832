import axios from 'axios'
import { Config } from '~/config/config'
import { HttpClient } from './htttp'

export class StoreService {
  public static async stateData() {
    return await HttpClient.get(`/centralinventory/whlocation/area_list/IN`, {
      headers: {
        'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
      },
    })
  }
  public static async StorePlaceID(body: { placeID: any }) {
    return HttpClient.post(`/centralinventory/warehouse/placeID`, body, {
      headers: {
        'x-api-key': '3de5bba2-9861-4f98-8e34-a083fd6c124b',
      },
    })
  }
  public static async cityData(state: any) {
    return await HttpClient.get(
      `centralinventory/whlocation/area_list/IN/${state}`,
      {
        headers: {
          'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
        },
      }
    )
  }

  public static async areaData(state: any, city: any) {
    return await HttpClient.get(
      `/centralinventory/whlocation/area_list/IN/${state}/${city}`,
      {
        headers: {
          'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
        },
      }
    )
  }

  public static async finalData(state: any, city: any, area: any) {
    return await HttpClient.get(
      `/centralinventory/whlocation/area_list/IN/${state}/${city}/${area}`,
      {
        headers: {
          'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
        },
      }
    )
  }

  public static async finalDatas(state: any, city: any, area: any) {
    return await HttpClient.get(
      `/centralinventory/whlocation/area_list/IN/${state}/${city}/${area}`,
      {
        headers: {
          'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
        },
      }
    )
  }

  public static async BrandSearch(brandlists: any) {
    return await HttpClient.get(
      `/centralinventory/whlocation/storearea/${brandlists}`,
      {
        headers: {
          'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
        },
      }
    )
  }

  public static async AreaSearch() {
    return await HttpClient.get(`/centralinventory/whlocation/storearea`, {
      headers: {
        'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
      },
    })
  }

  public static async Allowlocation() {
    return await HttpClient.get(`/location`)
  }

  public static async mapData(kilometer: any, pincode: any) {
    return await HttpClient.get(
      `/centralinventory/whlocation/storebypin/${pincode}/${kilometer}`,
      {
        headers: {
          'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
        },
      }
    )
  }

  public static async dataPin(kilometer: any, pincode: any) {
    return await HttpClient.get(
      `/centralinventory/whlocation/storebypin/${pincode}/${kilometer}`,
      {
        headers: {
          'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
        },
      }
    )
  }

  public static async sharelocation(pincode: any) {
    return pincode?.length
      ? await HttpClient.get(
          `/centralinventory/whlocation/districtbypincode/` + pincode,
          {
            headers: { 'x-api-key': Config.STORE_BASE_AUTH_TOKEN },
          }
        )
      : null
  }

  public static async getStoreItemsWithStoreId(id: any, item?: number) {
    let asonDate = new Date().toISOString().slice(0, 10).replace(/-/gi, '')
    return await HttpClient.get(
      `/qr/api/apxapi/GetStockInfo?CompanyCode=${
        Config.STORE_COMPANY_CODE
      }&ItemCode=${
        !!item ? item : 0
      }&BranchCode=${id}&AsonDate=${asonDate}&BranchCategory=Website - Active`,
      {
        headers: {
          'content-type': 'application/json',
          userid: Config.STORE_USER_ID,
          securitycode: `${Config.STORE_SECURITY_CODE}`,
        },
      }
    )
  }

  public static async getEmployeeReferer(id: any) {
    return HttpClient.get(`/store/api/oc3/staffdetails.php?emplid=${id}`, {
      headers: {
        access_token: `${Config.EMP_REFERER_TOKEN}`,
      },
    })
  }
  public static async getReceiptVoucherDetails(invoice: any, date: any) {
    return HttpClient.get(
      `/qr/api/apxapi/GetReceiptsVoucherDetails?CompanyCode=${Config.STORE_COMPANY_CODE}&sVoucherNumber=${invoice}&lVoucherDate=${date}`,
      {
        headers: {
          userid: Config.STORE_USER_ID,
          securitycode: `${Config.STORE_SECURITY_CODE}`,
        },
      }
    )
  }
}
