import style from './multi-select-tag.module.scss'

interface MultiSelectTagProps {
  type: MultiSelectTagType
  items: any
  onChange: (val: any) => void
  name: string
}

export enum MultiSelectTagType {
  Multiple,
  Single,
}

export const MultiSelectTag = (props: MultiSelectTagProps) => {
  const toggleValue = (_e: any, item: any) => {
    if (props.type == MultiSelectTagType.Single) {
      props.onChange(item)
    } else {
    }
  }

  const element = props.items.map((res: any, i: number) => (
    <label key={i} className={style.multiSelectTag__item}>
      <input
        onChange={(e) => toggleValue(e, res)}
        type={props.type == MultiSelectTagType.Single ? 'radio' : 'checkbox'}
        name={props.name}
      />
      <span>{res}</span>
    </label>
  ))

  return <div className={style.multiSelectTag}>{element}</div>
}
