import axios from 'axios'
import Cookies from 'js-cookie'
import { Config } from '~/config/config'
import { IsMobile, Logger } from '~/util'

export const createCookie = (name: string, val: any) => {
  Cookies.set(name, JSON.stringify(val), {
    path: '/',
    domain: Config.COOKIE_DOMAIN,
  })
}

export const getDataFromCookie = (name: string) => {
  const data = Cookies.getJSON(name)
  return data
}

export const PaymentClient = axios.create({
  baseURL: Config.PAYMENT.BASE_URL,
})

const HttpClient = axios.create({
  baseURL: Config.BASE_URL,
  withCredentials: true,
})

HttpClient.interceptors.request.use(
  (req: any) => {
    let setToken: any = Config.TOKEN_NAME
    const token = Cookies.getJSON(setToken)
    if (!!!req.headers.Authorization) {
      req.headers.Authorization = `Bearer ${String(token).replace('%22', '')}`
    }

    if (IsMobile()) {
      req.headers.mode = 'mobile_web'
    } else {
      req.headers.mode = 'web'
    }
    return req
  },
  (err) => Promise.reject(err)
)

HttpClient.interceptors.response.use(
  (res) => {
    const expireAnonPath = ['/auth/login', '/auth/logout']
    let setToken: any = Config.TOKEN_NAME
    if (res.status == 200) {
      if (res.data) {
        if (res.data.data) {
          if (res.data.data.access_token) {
            let token = String(res.data.data.access_token).replace('%22', '')
            createCookie(setToken, token)
          }
          if (res.data.data.customer_id) {
            createCookie('customer_id', res.data.data.customer_id)
          }
          if (res.data.data['x-anon']) {
            const someDate = new Date()
            const numberOfDaysToAdd = 6
            const result = someDate.setDate(
              someDate.getDate() + numberOfDaysToAdd
            )
            Cookies.set('x-anon', JSON.stringify(res.data.data['x-anon']), {
              path: '/',
              domain: Config.COOKIE_DOMAIN,
              secure: true,
              expires: new Date(result),
              sameSite: 'None',
            })
          }
          if (expireAnonPath.includes(res.config.url)) {
            Cookies.remove('x-anon')
          }
        }
      }
      return res
    }

    return res
  },
  (err: any) => {
    // white list array is used to ignore logout of use in case of unauthorized error message
    let whitelist = ['/recentProduct', '/review', '/servicecenter/get/public']
    let endpoint = String(err.request.responseURL)
      .split('/v1')[1]
      ?.split('?')[0]
    let setToken: any = Config.TOKEN_NAME
    if (err.response?.status == 401) {
      if (window) {
        if (Cookies.getJSON()[setToken]) {
          if (!whitelist.includes(endpoint)) {
            Cookies.remove(setToken)
            Cookies.remove('customer_id')
            Cookies.remove('session')
            Cookies.remove('user')
            window.document
            window.location.reload()
          }
          Logger.error('[ERROR]', whitelist.includes(endpoint))
        }
      }
    }

    return Promise.reject(err)
  }
)

export function getServerSideCookie(ctx: any, cookieName: any) {
  const value = `; ${ctx.req.headers.cookie}`
  const parts: any = value.split(`; ${cookieName}=`)
  if (parts.length === 2) {
    const item = parts
      .pop()
      .split(';')
      .shift()
      .replace(/\\r|\\n|\\t/, '')
    return item
  }
  return ''
}

export const HttpServer = axios.create({
  baseURL: Config.BASE_URL,
  withCredentials: true,
})

export const ObjToQueryString = (object: any): string => {
  let result = '?'
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      result += `${key}=${object[key]}&`
    }
  }
  return result
}

export { HttpClient }
