import style from '../app-bar.module.scss'
import { AuthState } from '~/stores/auth'

import { AccountDropDown } from '../account-dropdown/account-dropdown'
import { useLayoutEffect, useState } from 'react'
import Img from '../../img/img'

/**
 * app bar account contains basic account menu
 * @returns {JSX.Element} returns app bar account contains basic  account menu
 */
export const AppBarAccount = () => {
  const authState = AuthState.useContainer()
  const [isSmallHead, setIsSmallHead] = useState(false)
  const [listDropDown, setListDropDown] = useState(false)
  useLayoutEffect(() => {
    setIsSmallHead(window.innerWidth <= 800)
    window.onresize = (_e) => {
      setIsSmallHead(window.innerWidth <= 800)
    }
  }, [])
  return (
    <div
      onClick={() => {
        if (!authState.state.id) {
          authState.setShowLoginForm(true)
        }
      }}
      className={`${style.app_bar__account} text-right justify-center pt-1 ml-1`}
      onMouseEnter={() => setListDropDown(true)}
      onMouseLeave={() => setListDropDown(false)}
    >
      <div className={style.text}>
        <div className={`${style.caption}`}>My Account</div>

        {authState.state.id && !!authState.state.firstName ? (
          <b className={`${style.name}`}>{authState.state.firstName}</b>
        ) : (authState.state.id && authState.state.firstName == 'Guest') ||
          (authState.state.id && authState.state.firstName == '') ? (
          <b className={`${style.name}`}>
            {authState.state.firstName?.length
              ? authState.state.firstName
              : 'Guest'}
          </b>
        ) : (
          <b className={`${style.name}`}>Sign In</b>
        )}
      </div>
      {!!authState.state.image ? (
        <img
          alt="avatar"
          className={`${style.mobile_profileavatar}`}
          // style={{ borderRadius: '50%', marginLeft:6,marginTop:6 }}
          src={`${authState.state.image}`}
          // loader={() => authState.state.image}
          // blurDataURL={authState.state.image}
          placeholder="blur"
        />
      ) : (
        <div
          className={style.avatar}
          style={{
            backgroundImage:
              'url(https://img.poorvika.com/common/user_profile.webp)',
          }}
        />
      )}
      {listDropDown && !!authState.state.id && !isSmallHead && (
        <div className={style.app_bar__account__list}>
          <AccountDropDown />
        </div>
      )}
    </div>
  )
}
