import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { Logger } from '~/util'
import { Encrypt } from '~/util/encryption'
import { StoreService } from '~/services/stores.service'
import Cookies from 'js-cookie'

export interface Notify {
  id?: number
  message: string
  type: 'SUCCESS' | 'ERROR' | 'WARNING'
  cancalable?: boolean
  close?: () => void
}

const NotificationState = () => {
  const [notifications, setNotifications] = useState<Notify | null>(null)
  const [pageTransition, setPageTransition] = useState(false)
  const [cancalabel, setCancalabel] = useState(false)
  const [isStore, setIsStore] = useState(false)
  const [branchName, setBranchName] = useState('')
  const [storeName, setStoreName] = useState('')
  const [loadingText, setLoadingText] = useState(true)

  const router = useRouter()
  useEffect(() => {
    let storeCodeName = Cookies.get('store')
    setStoreName(!!storeCodeName ? storeCodeName : '')
    setIsStore(!!storeCodeName)
    if (!!storeCodeName) {
      StoreService.getStoreItemsWithStoreId(storeCodeName)
        .then((res) => {
          if (!!res.data.Data) {
            setBranchName(res.data.Data[0].BRANCH_NAME)
            setLoadingText(false)
          }
        })
        .catch((error) => {
          Logger.error(error)
          setLoadingText(false)
        })
    }
  }, [router, storeName])

  const appNotification = (
    message: any,
    type: 'SUCCESS' | 'ERROR' | 'WARNING',
    options?: { cancalable: boolean; milliseconds: number }
  ) => {
    let msg = ''
    if (typeof message != 'string') {
      msg = message.message
    } else {
      msg = message
    }
    const id = Date.now()
    setNotifications({ message: String(msg), type, id })
    Logger.error(msg)
    if (!!options) {
      if (options.cancalable) {
        setCancalabel(options.cancalable)
      }
      if (options.milliseconds) {
        setTimeout(() => {
          setNotifications(null)
        }, options.milliseconds)
      }
    } else {
      setTimeout(() => {
        setNotifications(null)
      }, 3000)
    }
  }
  const removeNotify = () => {
    setNotifications(null)
  }
  return {
    notifications,
    appNotification,
    removeNotify,
    setPageTransition,
    pageTransition,
    cancalabel,
    isStore,
    setIsStore,
    storeName,
    setStoreName,
    branchName,
    setBranchName,
    loadingText,
    setLoadingText,
  }
}

export const UseNotificationState: any = createContainer(NotificationState)
