import { FC } from 'react'

export type CheveronLeftProps = {
  iconcolor?: string
  strokeWidth?: number
  slidarrow?: any
}
export const CheveronLeft: FC<CheveronLeftProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    // stroke={props.iconcolor ? props.iconcolor : 'currentColor'}
    stroke={
      props.slidarrow
        ? 'currentColor'
        : props.iconcolor
        ? props.iconcolor
        : 'currentColor'
    }
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth ? props.strokeWidth : 2}
      d="M15 19l-7-7 7-7"
    />
  </svg>
)
