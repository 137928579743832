import { useEffect } from 'react'
import { UseNotificationState } from '~/stores/notify'
import style from './notification.module.scss'
import { NotifyEle } from './notify'

export const NotifyWrapper = () => {
  const notify = UseNotificationState.useContainer()
  return (
    <div className={style.notifyWrapper}>
      {notify.notifications ? (
        <NotifyEle
          key={notify.notifications.id}
          message={notify.notifications.message}
          type={notify.notifications.type}
          cancalable={notify.cancalabel}
          close={() => notify.removeNotify()}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
