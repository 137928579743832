import { AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import router from 'next/router'
import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { Config } from '~/config/config'
import { AuthService } from '~/services/auth.service'
import { UserService } from '~/services/user.service'
import { Logger } from '~/util'
import { Encrypt, EncryptObject } from '~/util/encryption'
import { UseNotificationState } from './notify'

/**
 * Logged in user data are stored in this structure {@link AuthState}
 * @memberof AuthState
 * @category Stores
 * @alias User
 */
export interface User {
  id: string
  firstName: string
  mobileno: string
  email: string
  image?: string
  customerType?: string
  pincode?: string
  crm_customer_id?: string
}

/**
 * Return type provided by AuthState
 * @memberof AuthState
 * @category Stores
 * @alias AuthStateReturns
 */
export interface AuthStateReturns {
  updateUser: (data: User) => void
  isLoggedIn: () => boolean
  logout: () => Promise<void>
  login: (username: string, password: string) => Promise<AxiosResponse<any>>
  register: (
    username: string,
    password: string,
    mobileno: string,
    email: string
  ) => Promise<AxiosResponse<any>>
  forgotPassword: (userdata: string) => Promise<AxiosResponse<any>>
  validateOtp: (otp: string, username: string) => Promise<AxiosResponse<any>>
  state: User
  getToken: () => Promise<any>
  initUserData: () => Promise<any>
  showLoginForm: boolean
  setShowLoginForm: (val: boolean) => void
  pinCode: string
  updatePinCode: (val: string) => void
}

/**
 * this is a global state for logged in user
 * @memberof AuthState
 * @alias AuthStateFunction
 * @returns AuthStateReturns
 */
function AuthStateFunction() {
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [pinCode, setPinCode] = useState('')
  const notify = UseNotificationState.useContainer()
  /**
   * state for Auth State function
   */
  const [user, setUser] = useState({
    id: '',
    firstName: '',
    mobileno: '',
    email: '',
    image: '',
    // pincode: '',
  } as User)
  const [loadingUser, setLoadingUser] = useState(true)

  /**
   * returns true if the user is loged in
   * else return false
   * @returns boolean
   */
  const isLoggedIn = (): boolean => {
    return !!user.id
  }

  /**
   *  update user data and should avoid using this outside
   * auth state unless it absolutely required
   * @param data User
   */
  const updateUser = (data: User) => {
    localStorage.clear()
    setUser(data)
  }

  /**
   * clear user from session and the auth store
   */
  const clearUser = () => {
    setUser({
      id: '',
      firstName: '',
      email: '',
      mobileno: '',
      image: '',
      // pincode: '',
    })
  }

  /**
   * logout user from the platform
   */
  const logout = async () => {
    // clear user when logged out
    // let setToken: any = Config.TOKEN_NAME
    try {
      // router.('/')
      await AuthService.logout()
        .then(() => {
          clearUser()
          // Cookies.remove(setToken)
          Cookies.remove('customer_id')
          Cookies.remove('session')
          Cookies.remove('user')
        })
        .finally(() => {
          window.location.assign('/')
          // window.location.pathname
          //   ? window.location.pathname
          //   : window.location.assign('/')
          // router.push('/')
        })
    } catch (err) {}
  }

  /**
   * login user to the platform
   * @param username string
   * @param mobileno string
   * @param password string
   * @param email    string
   */
  const register = async (userdata: string) => {
    try {
      const data: any = await EncryptObject({
        userdata,
      })
      // updateUser when logged in
      return AuthService.register(data)
        .then((res) => {
          if (!!res.data.success) {
            return res
          }
          notify.appNotification(res.data.error.message, 'ERROR')
          return Promise.reject(res.data.error.message)
        })
        .catch((err) => {
          notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
          return Promise.reject(err)
        })
    } catch (err: any) {
      notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
      return Promise.reject(err)
    }
  }

  const verifyRegisterOtp = async (userdata: string, otp: string) => {
    try {
      const data: any = await EncryptObject({
        userdata,
        otp,
      })

      return AuthService.registerOtp(data)
        .then((res) => {
          return res
        })
        .catch((err) => {
          notify.appNotification(err.response.data.errorText, 'ERROR')
          return Promise.reject(err)
        })
    } catch (err: any) {
      notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
      return Promise.reject(err)
    }
  }

  const getUserProfile = async () => {
    try {
      const res = await UserService.getAccount()
      if (res.data.data.customerlist) {
        const data = res.data.data.customerlist[0]
        setUser({
          ...user,
          firstName: data.fullname,
          email: data.email,
          mobileno: data.mobileno,
          image: data.image,
        })
        setTimeout(() => {
          Logger.log('GAA', user)
        }, 2000)
        Logger.log()
      }
      return res
    } catch (err: any) {
      notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
      return Promise.reject(err)
    }
  }

  const updateProfileDetailFromProfilePage = async (
    fullname: string,
    email: string,
    mobileno: string,
    image: string
  ) => {
    try {
      const encryptedData = await EncryptObject({
        fullname,
        email,
        mobileno,
        image,
      })
      const res = await UserService.updateAccountDetail(encryptedData)
      return res
    } catch (err: any) {
      notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
      return Promise.reject(err)
    }
  }

  const updateProfile = async (
    fullname: string,
    email: string,
    mobileno: string,
    image: any,
    mobileotp: string,
    emailotp: string
  ) => {
    try {
      const encryptedData = await EncryptObject({
        fullname,
        email,
        mobileno,
        mobileotp,
        emailotp,
        image,
      })
      const res = await UserService.updateAccountDetail(encryptedData)
      notify.appNotification(`${res.data.data.message}`, 'SUCCESS')
      getUserProfile()
        .then((res) => {
          let da = res.data.data.customerlist
          // alert(JSON.stringify(res.data.data.customerlist))
          const userData: User = {
            id: user.id,
            email: !!da.email ? da.email : '',
            firstName: !!da.first_name ? da.first_name : '',
            mobileno: !!da.mobileno ? da.mobileno : '',
            image: !!da.image ? da.image : '',
            customerType: !!da.customer_type ? da.customer_type : '',
            // pincode: !!da.pincode ? da.pincode : '',
          }
          Cookies.set('user', JSON.stringify(userData), {
            path: '/',
            domain: Config.COOKIE_DOMAIN,
          })
        })
        .catch(Logger.error)
      return res
    } catch (err: any) {
      notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
      return Promise.reject(err)
    }
  }

  const updateUserProfile = async (
    username: string,
    email: string,
    mobileno: string,
    password: string,
    image?: string,
    registerWith?: '' | 'email' | 'mobileno'
  ) => {
    try {
      let payload: any = {
        email,
        username,
        mobileno,
        password,
        image: image || '',
      }
      const data: any = await EncryptObject(payload)
      Logger.log(registerWith)

      // if (!!registerWith) {
      //   if (registerWith == "email") {
      //     data["email"] = ""
      //   } else {
      //     payload["mobileno"] = "";
      //   }
      // }
      return AuthService.addProfileDetail(data)
        .then((res) => {
          updateUserData(res)
          notify.appNotification(res.data.data.message, 'SUCCESS')
          return res
        })
        .catch((err) => {
          notify.appNotification(err.response.data.error.message, 'ERROR')
          return Promise.reject(err)
        })
    } catch (err: any) {
      notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
      return Promise.reject(err)
    }
  }

  /**
   * Forget password user to the platform
   * @param userdata string
   */
  const forgotPassword = async (userdata: string) => {
    const encText = new Encrypt({ text: userdata }).encrypt()
    // updateUser when logged in
    return AuthService.getOTPForLogin({ userdata: encText })
      .then((res) => {
        return res
      })
      .catch((err) => {
        notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
        return Promise.reject(err)
      })
  }

  //   /**
  // * Forget password user to the platform
  // * @param otp string
  // */
  //   const generateOtp = async () => {
  //     // updateUser when logged in
  //     return AuthService.generateOtp()
  //       .then((res) => {

  //         return res
  //       })

  //   }

  /**
   * Forget password user to the platform
   * @param otp string
   */
  const validateOtp = async (otp: string, userdata: string) => {
    const data: any = await EncryptObject({ userdata, otp })
    return AuthService.validateOtp(data)
      .then((res) => {
        updateUserData(res)
        return res
      })
      .catch((err) => {
        notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
        return Promise.reject(err)
      })
  }

  const updatePassword = async (password: string) => {
    const data: any = await EncryptObject({ password })
    return AuthService.updatePassword(data)
      .then((res) => res)
      .catch((err) => {
        notify.appNotification(err.response.data.error.message, 'ERROR')
        return Promise.reject(err)
      })
  }
  const changePassword = async (newpassword: any) => {
    const data: any = await EncryptObject({ newpassword })
    return AuthService.changePassword(data)
      .then((res) => res)
      .catch((err) => {
        notify.appNotification(err.response.data.error.message, 'ERROR')
        return Promise.reject(err)
      })
  }

  /**
   * login user to the platform
   * @param username string
   * @param password string
   */
  const login = async (username: string, password: string, active) => {
    const encryptedData: any = await EncryptObject({
      username,
      password,
      loginstatus: active,
    })
    // updateUser when logged in
    return await AuthService.loginRegular(encryptedData)
      .then((res) => {
        updateUserData(res)
        setShowLoginForm(false)
        return res
      })
      .catch((err) => {
        notify.appNotification(`${err.response.data.error.message}`, 'ERROR', {
          milliseconds: 10000,
        })
        return Promise.reject(err)
      })
  }

  const updateUserData = (res: any) => {
    const { data } = res.data
    const userData: User = {
      id: data.customer_id,
      email: data.email,
      firstName: data.first_name,
      mobileno: data.mobileno,
      image: data.image,
      customerType: data.customer_type,
      pincode: data.pincode,
      crm_customer_id: data.crm_customer_id,
    }
    if (!!data.pincode) {
      updatePinCode(data.pincode)
    }
    // email: "arun.v@poorvika.com"
    // fullname: "arun vs"
    // image: "https://img.poorvika.com/test-files/101_12 (1).png"
    // mobileno: "8248602899"
    setUser(userData)
    Logger.log('setting cookie', Config.COOKIE_DOMAIN, ' ', data)

    Cookies.set('user', JSON.stringify(data), {
      path: '/',
      domain: Config.COOKIE_DOMAIN,
    })
  }

  /**
   * Initialize logged in user data in the application for global use
   */
  const initUserData = async () => {
    setLoadingUser(true)
    const userData: any = Cookies.getJSON('user')
    if (userData) {
      let data = {
        id: userData['customer_id'],
        email: userData['email'],
        firstName: userData['first_name'],
        mobileno: userData['mobileno'],
        image: userData['image'],
        customerType: userData['customer_type'],
        pincode: userData['pincode'],
      }

      if (userData) {
        setUser(data)
      }
    }
    setTimeout(() => {
      setLoadingUser(false)
    }, 500)
  }

  /**
   * gets token for the app to perform properly
   * @returns Promise<APIResponse>
   */
  const getToken = () =>
    AuthService.getToken()
      .then((res) => {
        const expires = new Date()
        expires.setDate(expires.getDate() + 1)
        const token = res.data.data.access_token
        Cookies.set(`${Config.TOKEN_NAME}`, token, {
          path: '/',
          domain: Config.COOKIE_DOMAIN,
        })
        return res
      })
      .catch((err: any) => {
        notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
        return Promise.reject(err)
      })

  const updatePinCode = (value: string) => {
    setPinCode(value)
    localStorage.setItem('pinCode', JSON.stringify({ pinCode: value }))
    localStorage.setItem('myData', value)
  }

  const clearPinCode = (value: string) => {
    setPinCode(value)
    localStorage.removeItem('pinCode')
    localStorage.removeItem('myData')
    // localStorage.clear()
  }
  const updateCookie = () => {
    let cook = Cookies.getJSON()
    if (cook['user']) {
      let usr: any = cook['user']
      let us = {
        ...usr,
        ...user,
        first_name: user.firstName,
      }
      delete us.firstName
      let res = Cookies.set('user', JSON.stringify(us), {
        path: '/',
        domain: Config.COOKIE_DOMAIN,
      })
      Logger.log('cook', res)
    }
  }

  useEffect(() => {
    updateCookie()
  }, [user])

  return {
    updateUser,
    loadingUser,
    setLoadingUser,
    isLoggedIn,
    logout,
    login,
    register,
    forgotPassword,
    getUserProfile,
    // validateOtp,
    state: user,
    setProfileImage: (url: any) => setUser({ ...user, image: url }),
    getToken,
    initUserData,
    showLoginForm,
    setShowLoginForm,
    pinCode,
    setPinCode,
    updatePinCode,
    updatePassword,
    validateOtp,
    verifyRegisterOtp,
    updateUserProfile,
    updateProfileDetailFromProfilePage,
    updateProfile,
    changePassword,
    clearPinCode,
  }
}

export const AuthState: any = createContainer(AuthStateFunction)
