import { FC } from 'react'

export type ExitIconProps = {
  iconColor?: string
  className?: string
  width?: string
}
export const ExitIcon: FC<ExitIconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={props.width ? props.width : '2'}
    stroke={props.iconColor ? props.iconColor : 'currentColor'}
    className={props.className ? props.className : 'h-6 w-6'}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
    />
  </svg>
)
